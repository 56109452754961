import React, { useEffect, useState } from "react";
import "../styles/general.css";
import "../styles/blogpost.css";
import { useTranslation } from "react-i18next";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import Skeleton from "@mui/material/Skeleton";
import { Link, useNavigate } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemText,
  Paper,
  styled,
  useMediaQuery,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Grid,
} from "@mui/material";
import ReactGA from "react-ga4";
//Components
import MarkDownReader from "../components/MarkDownReader/MarkDownReader";
import CustomCard from "../components/Card/CustomCard";
import { useParams } from "react-router-dom";
import { checkStoriesSessionStorage } from "../utils/firebaseUtils";
import {
  convertFormatTimestamp,
  google_analytics_measurement_id,
} from "../utils/dataUtils";
import useScreenRotation, { scrollToStartSection } from "../utils/uiUtils";
import {
  checkPostsSessionStorage,
  findAdjacentPosts,
  findSuggestedPosts,
  getPostById,
} from "../utils/blogFirebaseUtils";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: 60,
  lineHeight: "60px",
}));

export default function BlogPost(props) {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const isDesktopScreen = useMediaQuery("(min-width:1025px)");
  const isLittleMobileScreen = useMediaQuery("(max-width:500px)");
  const isRotated = useScreenRotation();
  const navigate = useNavigate();
  ReactGA.initialize(google_analytics_measurement_id);

  const [state, setState] = useState({
    postObj: {},
    postLocation: [],
    idPostFromUrl: "",
    imageLoaded: false,
    isFirstRender: false,
    // currentMarkdown: "",
  });

  function setImageLoaded() {
    // console.log(
    //   "Ora imageLoaded e': ",
    //   state.imageLoaded,
    //   "e andrà a : ",
    //   state.imageLoaded === true ? false : true
    // );
    setState({
      ...state,
      imageLoaded: state.imageLoaded === true ? false : true,
    });
  }

  function scrollToSection() {
    const sectionElement = document.getElementById("body-story");

    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
    }
  }

  function renderInsights() {
    // console.log("RENDER INSIGHTS SI E' RIPETUTO");
    return (
      <>
        <List
          style={{ color: "white", marginBottom: "20px" }}
          className="left-menu"
        >
          <ListItem
            disablePadding
            style={{ display: "flex", flexDirection: "column" }}
          >
            <ListItemText
              style={{ fontWeight: "bold", fontSize: "20px" }}
              primary={t("postCategories") + ":"}
            />
          </ListItem>
          <ListItem
            disablePadding
            style={{
              display: "flex",
              flexDirection: "column",
              fontStyle: "italic",
            }}
          >
            {state.postObj?.categories &&
              state.postObj?.categories.map((category, key) => (
                <ListItemText primary={t(`${category}`)} />
              ))}
            {state.postObj?.categories?.length === 0 && (
              <>{t("noCategories")}</>
            )}
          </ListItem>
        </List>
      </>
    );
  }

  function renderButtons() {
    return (
      <>
        <div className="footer-story-buttons">
          <div className="button-previous-story">
            <Button
              variant="outlined"
              disabled={state.previousPost == null}
              sx={{ color: "white" }}
            >
              <Link
                to={
                  state.previousPost != null &&
                  `/blog/blogpost/${state.previousPost}/${
                    i18n.language === "it" ? state.postUrlIt : state.postUrlEn
                  }`
                }
                onClick={() => fetchPost(state.previousPost)}
                className="link-buttons"
              >
                {isDesktopScreen ? (
                  <>
                    <ArrowCircleLeftIcon />‎ ‎‎ ‎ ‎ {t("previousPost")}
                  </>
                ) : (
                  <>
                    <ArrowCircleLeftIcon />‎ ‎‎ ‎ ‎{" "}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {t("previousPostMobile")}
                    </div>
                  </>
                )}
              </Link>
            </Button>
          </div>
          <div className="button-next-story">
            <Button
              variant="outlined"
              disabled={state.nextPost == null}
              sx={{ color: "white" }}
            >
              <Link
                to={
                  state.nextPost != null &&
                  `/blog/blogpost/${state.previousPost}/${
                    i18n.language === "it" ? state.postUrlIt : state.postUrlEn
                  }`
                }
                onClick={() => fetchPost(state.nextPost)}
                className="link-buttons"
              >
                {isDesktopScreen ? (
                  <>
                    {t("nextPost")}
                    ‎ ‎‎ ‎ ‎<ArrowCircleRightIcon />
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      {t("nextPostMobile")}
                    </div>
                    ‎ ‎‎ ‎ ‎<ArrowCircleRightIcon />
                  </>
                )}
              </Link>
            </Button>
          </div>
        </div>
      </>
    );
  }

  function renderSuggestedPosts() {
    return (
      <div className="side-stories-container">
        <p
          style={{
            fontSize: "16px",
            color: "white",
            fontWeight: "bold",
            marginBottom: "20px",
          }}
        >
          {t("otherRecentPosts")}
        </p>
        {state.suggestedPostsArray?.map((post, key) => (
          <div style={{ marginBottom: "10px" }}>
            <Grid item xs={12} sm={6} md={3} key={key} className="card-grid">
              <CustomCard
                content={post}
                maxPicHeight="100px"
                minWidth="25vw"
                withText={false}
                url={`/blog/blogpost/${state.previousPost}/${
                  i18n.language === "it" ? state.postUrlIt : state.postUrlEn
                }`}
                refetchFunction={() => fetchPost(post.idDocument)}
                isBlog={true}
              />
            </Grid>
          </div>
        ))}
      </div>
    );
  }

  function renderSuggestedPostsMobile() {
    return (
      <div className="side-stories-container">
        <p
          style={{
            fontSize: "16px",
            color: "white",
            fontWeight: "bold",
            marginBottom: "20px",
          }}
        >
          {t("otherRecentPosts")}
        </p>
        {state.suggestedPostsArray?.map((post, key) => (
          <div style={{ marginBottom: "10px" }}>
            <Grid item xs={12} sm={6} md={3} key={key} className="card-grid">
              <CustomCard
                content={post}
                maxPicHeight="100px"
                minWidth="100vw"
                withText={false}
                url={`/blog/blogpost/${state.previousPost}/${
                  i18n.language === "it" ? state.postUrlIt : state.postUrlEn
                }`}
                refetchFunction={() => fetchPost(post.idDocument)}
                isBlog={true}
              />
            </Grid>
          </div>
        ))}
      </div>
    );
  }

  async function fetchPost(idDocument) {
    scrollToStartSection();
    let postId = idDocument != null ? idDocument : id.split("/").pop();
    try {
      let postData = await getPostById(postId);
      ReactGA.send({
        hitType: "pageview",
        page: "/blog/blogpost",
        title: `Blogpost view: ${postData.titleIt}`,
      });
      // console.log("(VIEW) Post data fetched: ", postData);
      let postLocationArray = [
        {
          latitude: postData?.location?.latitude,
          longitude: postData?.location?.longitude,
        },
      ];

      let postsJson = await checkPostsSessionStorage();
      let storiesJson = await checkStoriesSessionStorage();
      let suggestedPosts = findSuggestedPosts(postsJson.posts, postId);
      let buttonPosts = findAdjacentPosts(postsJson.posts, postData);
      let currentPostUrlIt = postData.urlIt;
      let currentPostUrlEn = postData.urlEn;

      setState({
        ...state,
        postObj: postData,
        postLocation: postLocationArray,
        postsJson: postsJson,
        previousPost: buttonPosts[0].previousPostId,
        previousPostUrl:
          i18n.language == "it"
            ? buttonPosts[0].previousPostUrlIt
            : buttonPosts[0].previousPostUrlEn,
        nextPost: buttonPosts[1].nextPostId,
        nextPostUrl:
          i18n.language == "it"
            ? buttonPosts[1].nextPostUrlIt
            : buttonPosts[1].nextPostUrlEn,
        idPostFromUrl: postId,
        suggestedPostsArray: suggestedPosts,
        imageLoaded: false,
        currentPost: postData,
        postUrlIt: currentPostUrlIt,
        postUrlEn: currentPostUrlEn,
        isFirstRender: true,
      });
    } catch (error) {
      console.error("Error fetching story:", error);
    }
  }

  function setUrl() {
    const fullURL = window.location.href;
    const segments = fullURL.split("/");
    const urlWithoutLangUrl = segments.slice(0, 6).join("/");
    const newPath = i18n.language === "it" ? state.postUrlIt : state.postUrlEn;
    // console.log("New Path:", newPath);
    // console.log("URL without Language URL:", urlWithoutLangUrl);
    window.history.pushState({}, "", `${urlWithoutLangUrl}/${newPath}`);
  }

  // function deleteEventListener() {
  //   console.log("Event listener removed");
  // }
  // function setCurrentMarkdown() {
  //   setState({
  //     ...state,
  //     currentMarkdown:
  //       i18n.language === "it"
  //         ? state.postObj?.urlMarkdownIt
  //         : state.postObj?.urlMarkdownEn,
  //   });
  // }

  useEffect(() => {
    fetchPost();
    // setCurrentMarkdown();
    console.log("18n.language", i18n.language);
  }, []);

  useEffect(() => {
    if (state.isFirstRender) {
      setUrl();
    }
    // setCurrentMarkdown();
  }, [i18n.language]);

  useEffect(() => {
    console.log("STATE :", state);
    // setCurrentMarkdown();
  }, [state]);

  // useEffect(() => {
  //   const fullURL = window.location.href;
  //   const segments = fullURL.split("/");
  //   const urlWithoutLangUrl = segments.slice(0, 6).join("/");
  //   const newPath = i18n.language === "it" ? state.postUrlIt : state.postUrlEn;
  //   console.log("New Path:", newPath);
  //   console.log("URL without Language URL:", urlWithoutLangUrl);
  //   window.history.pushState({}, "", `${urlWithoutLangUrl}/${newPath}`);
  // }, [state.isLanguageChanged]);

  // useEffect(() => {
  //   console.log("Navigation triggered");
  //   let path = `/blog/blogpost/${state.previousPost}/${
  //     i18n.language === "it" ? state.postUrlIt : state.postUrlEn
  //   }`;
  //   window.history.pushState({}, "", "/new-path");
  //   //navigate(path);
  // }, [
  //   i18n.language,
  //   state.idPostFromUrl,
  //   state.postUrlIt,
  //   state.postUrlEn,
  //   navigate,
  // ]);

  return (
    <div id="start">
      {state.postObj && (
        <div>
          {state.postObj?.urlWebImage ? (
            <>
              {!state.imageLoaded && (
                <div
                  className="background-picture"
                  style={{
                    backgroundColor: "#3b4257",
                    zIndex: "1",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Skeleton
                    variant="rectangular"
                    width={"80%"}
                    height={"60%"}
                    style={{ zIndex: "1" }}
                  />
                </div>
              )}

              <img
                src={state.postObj?.urlWebImage}
                className="background-picture"
                onLoad={setImageLoaded}
              ></img>
            </>
          ) : (
            <Skeleton
              sx={{ bgcolor: "grey.900", height: "100%" }}
              variant="rectangular"
              className="background-picture"
            />
          )}

          <div className="dark-shade"></div>
          <div className="dark-shade-bottom-up"></div>
          <div className="dark-shade-bottom-down"></div>
          <div className="main-container-component">
            {isDesktopScreen && (
              <>
                {state.postObj?.titleIt && state.postObj?.titleEn ? (
                  <div
                    className="title shade-title"
                    style={{ zIndex: 1, marginTop: "150px" }}
                  >
                    {i18n.language == "it" && state.postObj?.titleIt}
                    {i18n.language == "en" && state.postObj?.titleEn}
                  </div>
                ) : (
                  <Skeleton
                    variant="rounded"
                    height={100}
                    sx={{
                      width: "50%",
                      zIndex: 1,
                      marginTop: "150px",
                    }}
                  />
                )}

                {state.postObj?.timestamp ? (
                  <div
                    className="title shade-title"
                    style={{ zIndex: 1, marginTop: "-20px", fontSize: "25px" }}
                  >
                    {t("publicationDate") +
                      "  " +
                      convertFormatTimestamp(state.postObj?.timestamp)}
                  </div>
                ) : (
                  <Skeleton
                    variant="rounded"
                    height={100}
                    sx={{
                      width: "50%",
                      zIndex: 1,
                      marginTop: "150px",
                    }}
                  />
                )}

                <div
                  className="shade-buttons"
                  style={{
                    zIndex: 1,
                    width: "100%",
                    marginTop: "50px",
                  }}
                >
                  {renderButtons()}
                </div>
              </>
            )}
            {!isDesktopScreen && (
              <div
                style={{
                  zIndex: 1,
                  height: "90vh",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                {state.postObj?.titleIt && state.postObj?.titleEn ? (
                  <div
                    className="title shade-title"
                    style={{
                      zIndex: 1,
                      marginTop: "-30vh",
                      textAlign: "center",
                    }}
                  >
                    {i18n.language == "it" && state.postObj?.titleIt}
                    {i18n.language == "en" && state.postObj?.titleEn}
                  </div>
                ) : (
                  <Skeleton
                    variant="rounded"
                    height={100}
                    sx={{
                      width: "50%",
                      zIndex: 1,
                      marginTop: "150px",
                    }}
                  />
                )}
                {state.postObj?.timestamp ? (
                  <div
                    className="title shade-title"
                    style={{
                      zIndex: 1,
                      marginTop: "-35px",
                      marginBottom: "70px",
                      fontSize: "15px",
                    }}
                  >
                    {t("publicationDate") +
                      "  " +
                      convertFormatTimestamp(state.postObj?.timestamp)}
                  </div>
                ) : (
                  <Skeleton
                    variant="rounded"
                    height={100}
                    sx={{
                      width: "50%",
                      zIndex: 1,
                      marginTop: "150px",
                    }}
                  />
                )}
                <button
                  onClick={scrollToSection}
                  className="title shade-title"
                  style={{ fontSize: "15px", marginTop: "-5vh" }}
                >
                  {t("scrollDownToRead")}
                </button>
                <ExpandCircleDownIcon
                  onClick={scrollToSection}
                  style={{
                    color: "white",
                    marginTop: "-5vh",
                    fontSize: "50px",
                  }}
                />
              </div>
            )}
          </div>
          <div
            className={
              isLittleMobileScreen ? "post-container-mobile" : "post-container"
            }
          >
            <div className="story-left-part" id="body-story">
              <Item elevation={24} style={{ height: "auto" }}>
                {/* {state.currentMarkdown && (
                  <MarkDownReader markdown={state.currentMarkdown} />
                )} */}
                {i18n.language === "it" && state.postObj?.urlMarkdownIt && (
                  <MarkDownReader markdown={state.postObj?.urlMarkdownIt} />
                )}
                {i18n.language === "en" && state.postObj?.urlMarkdownEn && (
                  <MarkDownReader markdown={state.postObj?.urlMarkdownEn} />
                )}
              </Item>
            </div>
            {isDesktopScreen && (
              <div className="story-right-part">
                <div style={{ height: "30vh", paddingLeft: "20px" }}>
                  {renderInsights()}
                  {renderSuggestedPosts()}
                </div>
              </div>
            )}
          </div>
          {!isDesktopScreen && (
            <div className="footer-stories-container">
              {" "}
              <Accordion style={{ width: "90vw" }}>
                <AccordionSummary
                  expandIcon={<ExpandCircleDownIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    {t("insights")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{renderInsights()}</Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          )}
          <div style={{ marginBottom: "50px" }}>{renderButtons()}</div>
          {!isDesktopScreen && renderSuggestedPostsMobile()}
        </div>
      )}
    </div>
  );
}
