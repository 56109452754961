import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { convertFormatTimestamp } from "../../utils/dataUtils";

import "./customcard.css";
import "../../styles/general.css";

export default function CustomCard(props) {
  const { t, i18n } = useTranslation();
  const [imageLoaded, setImageLoaded] = useState(false); // State to manage image load status

  const handleClick = () => {
    if (props.refetchFunction) {
      props.refetchFunction();
    }
  };

  return (
    <div className="custom-card-container">
      <Card
        sx={{ maxWidth: props.maxWidth ? props.maxWidth : "100%" }}
        className="image-container"
      >
        <CardActionArea>
          <Link to={props.url} onClick={handleClick}>
            <div style={{ position: "relative" }}>
              {/* Preloaded image - Display this initially and hide when the main image loads */}
              {!imageLoaded && (
                <div
                  style={{
                    width: "100%",
                    height: `${
                      props.withText || props.isBlog ? "250px" : "100px"
                    }`,
                    backgroundColor: "#3b4257",
                  }}
                ></div>
              )}
              {/* Main image */}
              <CardMedia
                component="img"
                height="140"
                image={
                  props.content.urlMobileImage
                    ? props.content.urlMobileImage
                    : props.content.mobileImage
                }
                alt={props.content.titleIt}
                sx={{
                  maxHeight: `${props.maxPicHeight}`,
                  width: "100%",
                  display: imageLoaded ? "block" : "none", // Control display based on load status
                }}
                onLoad={() => setImageLoaded(true)} // Change load state when image has loaded
              />
              <Typography
                variant="h5"
                component="div"
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "bold",
                  padding: "10px 5px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  width: "90%", // Ensure the text does not overflow the container
                }}
              >
                {i18n.language === "it"
                  ? props.content.titleIt
                  : props.content.titleEn}
              </Typography>
            </div>
            {imageLoaded && (props.withText || props.isBlog) && (
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  width: "100%",
                }}
              >
                {props.withText && (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      maxHeight: "30px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {i18n.language === "it"
                      ? props.content.descriptionIt
                      : props.content.descriptionEn}
                  </Typography>
                )}
                {props.isBlog && (
                  <Typography
                    style={{ fontWeight: "bold" }}
                    color="text.secondary"
                  >
                    {t("publicationDate") + ": "}
                    {convertFormatTimestamp(props.content.timestamp)}
                  </Typography>
                )}
              </CardContent>
            )}
          </Link>
        </CardActionArea>
      </Card>
    </div>
  );
}

// import React, { useState } from "react";
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
// import Typography from "@mui/material/Typography";
// import { CardActionArea } from "@mui/material";
// import { useTranslation } from "react-i18next";
// import { Link } from "react-router-dom";
// import { convertFormatTimestamp } from "../../utils/dataUtils";

// import "./customcard.css";
// import "../../styles/general.css";

// export default function CustomCard(props) {
//   const { t, i18n } = useTranslation();
//   const [imageLoaded, setImageLoaded] = useState(false); // State to manage image load status

//   const handleClick = () => {
//     if (props.refetchFunction) {
//       props.refetchFunction();
//     }
//   };

//   return (
//     <div className="custom-card-container">
//       <Card
//         sx={{ maxWidth: props.maxWidth ? props.maxWidth : 320 }}
//         className="image-container"
//       >
//         <CardActionArea>
//           <Link to={props.url} onClick={handleClick}>
//             <div style={{ position: "relative" }}>
//               {/* Preloaded image - Display this initially and hide when the main image loads */}
//               {!imageLoaded && (
//                 <div
//                   style={{
//                     width: "500px",
//                     height: `${
//                       props.withText || props.isBlog ? "250px" : "100px"
//                     }`,
//                     backgroundColor: "#3b4257",
//                   }}
//                 ></div>
//               )}
//               {/* Main image */}
//               <CardMedia
//                 component="img"
//                 height="140"
//                 image={
//                   props.content.urlMobileImage
//                     ? props.content.urlMobileImage
//                     : props.content.mobileImage
//                 }
//                 alt={props.content.titleIt}
//                 sx={{
//                   maxHeight: `${props.maxPicHeight}`,
//                   width: "100%",
//                   minWidth: `${props.minWidth}`,
//                   maxWidth: `${props.maxWidth}`,
//                   display: imageLoaded ? "block" : "none", // Control display based on load status
//                 }}
//                 onLoad={() => setImageLoaded(true)} // Change load state when image has loaded
//               />
//               <Typography
//                 variant="h5"
//                 component="div"
//                 sx={{
//                   position: "absolute",
//                   top: "50%",
//                   left: 0,
//                   right: 0,
//                   transform: "translateY(-50%)",
//                   color: "white",
//                   backgroundColor: "rgba(0, 0, 0, 0.5)",
//                   textAlign: "center",
//                   fontSize: "20px",
//                   fontWeight: "bold",
//                   padding: "10px 0",
//                 }}
//               >
//                 {i18n.language === "it"
//                   ? props.content.titleIt
//                   : props.content.titleEn}
//               </Typography>
//             </div>
//             {imageLoaded && (props.withText || props.isBlog) && (
//               <CardContent
//                 sx={{
//                   display: "flex",
//                   flexDirection: "column",
//                   height: "100%",
//                   minWidth: `${props.minWidth}`,
//                   maxWidth: `${props.maxWidth}`,
//                 }}
//               >
//                 {props.withText && (
//                   <Typography
//                     variant="body2"
//                     color="text.secondary"
//                     sx={{
//                       maxHeight: "30px",
//                       minHeight: "30px",
//                       overflow: "hidden",
//                       whiteSpace: "nowrap",
//                       textOverflow: "ellipsis",
//                     }}
//                   >
//                     {i18n.language === "it"
//                       ? props.content.descriptionIt
//                       : props.content.descriptionEn}
//                   </Typography>
//                 )}
//                 {props.isBlog && (
//                   <Typography
//                     style={{ fontWeight: "bold" }}
//                     color="text.secondary"
//                   >
//                     {t("publicationDate") + ": "}
//                     {convertFormatTimestamp(props.content.timestamp)}
//                   </Typography>
//                 )}
//               </CardContent>
//             )}
//           </Link>
//         </CardActionArea>
//       </Card>
//     </div>
//   );
// }
