import React, { useRef, useEffect, useState } from "react";
import { gsap } from "gsap";
import Layer1 from "../../assets/images/parallax/layer_01.jpg";
import Layer2 from "../../assets/images/parallax/layer_02.png";
import Layer3 from "../../assets/images/parallax/layer_03.png";
import Layer4 from "../../assets/images/parallax/layer_04.png";
import "./parallax-gsap.css";
import { useTranslation } from "react-i18next";
import Navbar from "../HeaderBar/Navbar";

function ParallaxGsap() {
  const [background, setBackground] = useState(20);

  const { t } = useTranslation();

  const parallaxRef = useRef(null);
  const grassFirstLayer = useRef(null);
  const grassSecondLayer = useRef(null);
  const mountainsAndLake = useRef(null);
  const sky = useRef(null);

  useEffect(() => {
    document.body.style.margin = 0;
    document.body.style.padding = 0;

    const updateParallax = () => {
      const scrollY = window.scrollY;
      setBackground(Math.ceil((scrollY / window.innerHeight) * 100 + 20));

      gsap.to(grassFirstLayer.current, { y: -scrollY * 0.6 });
      gsap.to(grassSecondLayer.current, { y: -scrollY * 0.3 });
      gsap.to(mountainsAndLake.current, { y: -scrollY * 0.1 });
      gsap.to(sky.current, { top: scrollY * 0.1 });
    };

    window.addEventListener("scroll", updateParallax);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", updateParallax);
    };
  }, []);

  return (
    <>
      <div className="parallax-outer">
        <div
          ref={parallaxRef}
          style={{
            background: `linear-gradient(#262e48, #000000 ${background}%, #000000, #262e48 )`,
            height: "170vh", // Adjust the height based on your design
            overflow: "hidden", // Ensure no overflow
          }}
          className="parallax"
        >
          <div className="navbar-container">
            <Navbar />
          </div>
          <img
            ref={grassFirstLayer}
            className="grass-first-layer"
            src={Layer4}
            alt="Layer 4"
          />
          <img
            ref={grassSecondLayer}
            className="grass-second-layer"
            src={Layer3}
            alt="Layer 3"
          />
          <img
            ref={mountainsAndLake}
            className="mountains-and-lake"
            src={Layer2}
            alt="Layer 2"
          />
          <img ref={sky} className="stars" src={Layer1} alt="Layer 1" />
          <div
            className="absolute inset-0 flex items-center justify-center"
            style={{ marginTop: "-70vh", zIndex: "999" }}
          >
            <div className="main-title">
              <h1 className="text-7xl text-white font-thin">
                {t("landsOfTales")}
              </h1>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p className="background-quote">"{t("quote")}" - Salman Rushdie</p>
        </div>
        <div className="bottom-quote-background"></div>
      </div>
    </>
  );
}

export default ParallaxGsap;
