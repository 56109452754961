import React, { useEffect, useState } from "react";
import "../styles/general.css";
import "../styles/chapterdetail.css";
import "../styles/contacts.css";
import { useTranslation } from "react-i18next";
import { List, Paper, styled, useMediaQuery } from "@mui/material";
import contactsBg from "../assets/images/contactsBg.jpg";
import ReactGA from "react-ga4";
import AboutSocials from "../components/AboutSocials/AboutSocials";
import { google_analytics_measurement_id } from "../utils/dataUtils";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: 60,
  lineHeight: "60px",
}));

export default function Contacts(props) {
  const { t, i18n } = useTranslation();
  const isDesktopScreen = useMediaQuery("(min-width:1025px)");
  const isMiddleMobileScreen = useMediaQuery("(max-width:800px)");
  const isLittleMobileScreen = useMediaQuery("(max-width:500px)");

  const [state, setState] = useState({
    storyObj: {},
    storyLocation: [],
    idStoryFromUrl: "",
    startPagination: 0,
    endPagination: 12,
    currentPage: "",
    imposedPage: 0,
  });
  ReactGA.initialize(google_analytics_measurement_id);
  ReactGA.send({
    hitType: "pageview",
    page: "/contacts",
    title: "Contacts view",
  });
  useEffect(() => {
    console.log("18n.language", i18n.language);
  }, []);

  return (
    <div id="start">
      <div>
        <img src={contactsBg} className="background-picture"></img>
        <div className="dark-shade"></div>
        <div className="dark-shade-bottom-up"></div>
        <div className="dark-shade-bottom-down"></div>
        <div className="main-container-component">
          {isDesktopScreen && (
            <>
              <div className="title shade-title" style={{ zIndex: 1 }}>
                {t("contacts")}
              </div>
            </>
          )}
          {!isDesktopScreen && (
            <div
              style={{
                zIndex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <div
                className="title shade-title"
                style={{
                  zIndex: 1,
                  marginTop: "-30vh",
                  textAlign: "center",
                  marginTop: {
                    if(isSmallScreen) {
                      "15px";
                    },
                    if(isRotated) {
                      "25px";
                    },
                  },
                }}
              >
                {t("about")}
              </div>
            </div>
          )}
        </div>
        <div className="pic-and-overview-super-container">
          <div
            className={
              isDesktopScreen
                ? "pic-and-overview-container"
                : "overview-container-mobile-contacts"
            }
          >
            {i18n.language == "en" && (
              <List
                style={{
                  color: "white",
                  marginBottom: "20px",
                  fontFamily: "Roboto",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  paddingTop: "20px",
                  fontSize: "17px",
                }}
                className="left-menu"
              >
                <p
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "20px",
                    fontWeight: "bold",
                    marginBottom: "30px",
                  }}
                >
                  Do you want to reach me out?
                </p>
                For any information or request, you can reach me out at the
                following email address:
                <p
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "17px",
                    marginBottom: "10px",
                    fontWeight: "bold",
                  }}
                >
                  martino.manzolini@gmail.com
                </p>
                You can also find me on the following pages:
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                  <AboutSocials />
                </div>
              </List>
            )}
            {i18n.language == "it" && (
              <List
                style={{
                  color: "white",
                  marginBottom: "20px",
                  fontFamily: "Roboto",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  paddingTop: "20px",
                  fontSize: "17px",
                }}
                className="left-menu"
              >
                <p
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "20px",
                    fontWeight: "bold",
                    marginBottom: "30px",
                  }}
                >
                  Vuoi contattarmi?
                </p>
                Per qualunque informazione o richiesta, puoi scrivermi al
                seguente indirizzo di posta elettronica:
                <p
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "17px",
                    marginBottom: "10px",
                    fontWeight: "bold",
                  }}
                >
                  martino.manzolini@gmail.com
                </p>
                Altrimenti puoi trovarmi sulle seguenti pagine:
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                  <AboutSocials />
                </div>
              </List>
            )}
          </div>
        </div>
        <div style={{ height: "20vh" }}></div>

        {isMiddleMobileScreen && <div style={{ height: "20vh" }}></div>}
      </div>
    </div>
  );
}
