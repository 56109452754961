import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import englishLanguage from "./en.json";
import italianLanguage from "./it.json";
import LanguageDetector from "i18next-browser-languagedetector";

// Custom language detector
class CustomLanguageDetector extends LanguageDetector {
  constructor(services, options = {}) {
    super(services, options);
    this.type = "languageDetector";
  }

  detect() {
    const lang = localStorage.getItem("language"); // Get language preference from local storage
    return lang || "it"; // Return stored language preference or default to English
  }
}

i18n
  .use(initReactI18next)
  .use(CustomLanguageDetector) // Use the custom language detector
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: englishLanguage,
      it: italianLanguage,
    },
  });

// Save language preference to local storage when language changes
i18n.on("languageChanged", (lang) => {
  localStorage.setItem("language", lang);
});

export default i18n;
