import { db } from "../firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { urlChaptersJson, urlStoriesJson } from "./dataUtils";

const storiesCollectionRef = collection(db, "stories");
const storiesJsonCollectionRef = collection(db, "stories_json");
const chaptersCollectionRef = collection(db, "chapters");
const chaptersJsonCollectionRef = collection(db, "chapters_json");
const postsJsonCollectionRef = collection(db, "posts_json");

const getStories = async (
  page,
  rowsPerPage,
  lastElementTable,
  sortBy,
  sortDirection
) => {
  try {
    let data = null;
    let sortedStoriesQuery = query(
      // if sort is set, use it
      storiesCollectionRef,
      orderBy(sortBy, sortDirection)
    );
    if (lastElementTable.length !== 0) {
      console.log("Pagina successiva!");

      data = await getDocs(
        query(
          sortedStoriesQuery,
          startAfter(lastElementTable[lastElementTable.length - 1]),
          limit(rowsPerPage)
        )
      );
    } else {
      data = await getDocs(query(sortedStoriesQuery, limit(rowsPerPage)));
    }
    return data;
  } catch (error) {
    console.error("No, no, NO! ", error);
  }
};

const getStoriesFromSearch = async (searchBy, wordSearched) => {
  console.log("Parametri cercati: ", searchBy, wordSearched);

  try {
    const querySnapshot = await getDocs(
      query(
        storiesCollectionRef,
        where(searchBy, ">=", wordSearched),
        where(searchBy, "<=", wordSearched + "\uf8ff")
      )
    );

    // Debug: Log the results
    console.log("Search results: ", querySnapshot.docs);

    return querySnapshot;
  } catch (error) {
    console.error("Error searching stories:", error);
    throw error; // Rethrow the error to handle it further up the call stack if needed
  }
};

function isTimeToUpdateSessionStorage(dateString) {
  const providedDate = new Date(dateString);
  const currentDateTime = new Date();
  const timeDifference = currentDateTime - providedDate;
  const hoursDifference = timeDifference / (1000 * 60 * 60);
  return hoursDifference > 1 ? true : false;
}

async function checkStoriesSessionStorage() {
  let storiesData = await getStoriesJson();
  if (
    storiesData == null ||
    isTimeToUpdateSessionStorage(storiesData.timeStamp)
  ) {
    storiesData = await getJsonById(urlStoriesJson, "stories");
    storiesData.stories.sort((a, b) => {
      const dateA = new Date(a.timestamp);
      const dateB = new Date(b.timestamp);
      return dateB - dateA;
    });
    setJsonStoriesInSessionStorage(storiesData);
  }
  return storiesData;
}

async function getStoriesJson() {
  try {
    const jsonDataString = sessionStorage.getItem("storiesJson");
    const jsonData = JSON.parse(jsonDataString);
    //console.log("stories JSON data: ", jsonData);

    return jsonData;
  } catch (error) {
    console.error("Error retrieving stories JSON:", error);
    throw error;
  }
}

async function checkChaptersSessionStorage() {
  let chaptersData = await getChaptersJson();
  if (
    chaptersData == null ||
    isTimeToUpdateSessionStorage(chaptersData.timeStamp)
  ) {
    chaptersData = await getJsonById(urlChaptersJson, "chapters");
    chaptersData.chapters.sort((a, b) => {
      const dateA = new Date(a.timestamp);
      const dateB = new Date(b.timestamp);
      return dateB - dateA;
    });
    setJsonChaptersInSessionStorage(chaptersData);
  }
  return chaptersData;
}

async function getChaptersJson() {
  try {
    const jsonDataString = sessionStorage.getItem("chaptersJson");
    const jsonData = JSON.parse(jsonDataString);
    console.log("chapters JSON data: ", jsonData);

    return jsonData;
  } catch (error) {
    console.error("Error retrieving chapters JSON:", error);
    throw error;
  }
}

function printTime() {
  const isoTimestamp = new Date().toISOString();
  console.log(isoTimestamp);
  return isoTimestamp;
}

async function fetchStories(setState, state) {
  console.log("Scattata la fetch ---");
  let lastElementTable = null;
  let arrayOfEnds = state.endsForPagination;
  if (state.stories !== null) {
    if (state.directionPagination == "forward") {
      lastElementTable =
        state.responseStories.docs[state.responseStories.docs.length - 1];
      arrayOfEnds.push(lastElementTable);
    } else if (state.directionPagination == "backward") {
      arrayOfEnds.pop();
    }
  }
  console.log("Ends of pagination: ", arrayOfEnds);
  let stories = await getStories(
    state.page,
    state.rowsPerPage,
    arrayOfEnds,
    state.sortBy,
    state.sortDirection
  );
  setState({
    ...state,
    stories: stories?.docs.map((doc) => ({ ...doc.data(), id: doc.id })),
    responseStories: stories,
    endsForPagination: arrayOfEnds,
  });
}

async function getStoryById(storyId) {
  try {
    console.log("trying the fetch");
    const storyDocRef = doc(storiesCollectionRef, storyId);
    const storyDocSnapshot = await getDoc(storyDocRef);

    if (storyDocSnapshot.exists()) {
      const storyData = storyDocSnapshot.data();
      return { id: storyDocSnapshot.id, ...storyData };
    } else {
      console.log("No such document exists!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching story by ID:", error);
    throw error;
  }
}

async function getChapterById(chapterId) {
  try {
    const chapterDocRef = doc(chaptersCollectionRef, chapterId);
    const chapterDocSnapshot = await getDoc(chapterDocRef);

    if (chapterDocSnapshot.exists()) {
      const chapterData = chapterDocSnapshot.data();
      return { id: chapterDocSnapshot.id, ...chapterData };
    } else {
      console.log("No such document exists!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching chapter by ID:", error);
    throw error;
  }
}

async function getJsonById(jsonId, jsonType) {
  let collectionRef = "";
  switch (jsonType) {
    case "stories":
      collectionRef = storiesJsonCollectionRef;
      break;
    case "chapters":
      collectionRef = chaptersJsonCollectionRef;
      break;
    case "posts":
      collectionRef = postsJsonCollectionRef;
      break;
    default:
      collectionRef = storiesJsonCollectionRef;
  }
  try {
    const docRef = doc(collectionRef, jsonId);
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
      const jsonDataUrl = docSnapshot.data().json;
      const response = await fetch(jsonDataUrl);
      const jsonData = await response.json();
      switch (jsonType) {
        case "stories":
          setJsonStoriesInSessionStorage(jsonData);
          break;
        case "chapters":
          setJsonChaptersInSessionStorage(jsonData);
          break;
        case "posts":
          setJsonPostsInSessionStorage(jsonData);
          break;
      }
      console.log("JSON FETCHED:", jsonData); // Debug log to check the fetched JSON
      return jsonData;
    } else {
      console.log("No such document exists!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching by ID:", error);
    throw error;
  }
}

function setJsonStoriesInSessionStorage(jsonObject) {
  const storiesJsonWithTimestamp = {
    ...jsonObject,
    timeStamp: printTime(),
  };
  const serializedStoriesObject = JSON.stringify(storiesJsonWithTimestamp);
  sessionStorage.setItem("storiesJson", serializedStoriesObject);
}

function setJsonChaptersInSessionStorage(jsonObject) {
  const chaptersJsonWithTimestamp = {
    ...jsonObject,
    timeStamp: printTime(),
  };
  const serializedChaptersObject = JSON.stringify(chaptersJsonWithTimestamp);
  sessionStorage.setItem("chaptersJson", serializedChaptersObject);
}

function setJsonPostsInSessionStorage(jsonObject) {
  const postsJsonWithTimestamp = {
    ...jsonObject,
    timeStamp: printTime(),
  };
  const serializedPostsObject = JSON.stringify(postsJsonWithTimestamp);
  sessionStorage.setItem("postsJson", serializedPostsObject);
}

export {
  printTime,
  getStories,
  getStoriesFromSearch,
  getStoriesJson,
  fetchStories,
  getStoryById,
  getJsonById,
  setJsonStoriesInSessionStorage,
  checkStoriesSessionStorage,
  checkChaptersSessionStorage,
  getChapterById,
  isTimeToUpdateSessionStorage,
  setJsonPostsInSessionStorage,
};
