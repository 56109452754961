import React, { useEffect, useState } from "react";
import "../styles/general.css";
import "../styles/chapterdetail.css";
import { useTranslation } from "react-i18next";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import Skeleton from "@mui/material/Skeleton";
import { Link, useNavigate } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  styled,
  useMediaQuery,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";

//Components
import MarkDownReader from "../components/MarkDownReader/MarkDownReader";
import CustomCard from "../components/Card/CustomCard";
import { useParams } from "react-router-dom";
import {
  checkChaptersSessionStorage,
  checkStoriesSessionStorage,
  getChapterById,
} from "../utils/firebaseUtils";
import {
  findAdjacentChapters,
  google_analytics_measurement_id,
  storiesChapterInsights,
} from "../utils/dataUtils";
import useScreenRotation, { scrollToStartSection } from "../utils/uiUtils";
import SkeletonCardsGrid from "../components/Card/SkeletonCardsGrid";
import CustomPagination from "../components/Pagination/CustomPagination";
import ReactGA from "react-ga4";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: 60,
  lineHeight: "60px",
}));

export default function StoryDetail(props) {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const isDesktopScreen = useMediaQuery("(min-width:1025px)");
  const isSmallScreen = useMediaQuery("(max-width:900px)");
  const isLittleMobileScreen = useMediaQuery("(max-width:500px)");
  const isReallyMobileScreen = useMediaQuery("(max-width:400px)");
  const isRotated = useScreenRotation();

  const [state, setState] = useState({
    storyObj: {},
    storyLocation: [],
    idStoryFromUrl: "",
    startPagination: 0,
    endPagination: 12,
    currentPage: "",
    imposedPage: 0,
    isFirstRender: false,
  });

  ReactGA.initialize(google_analytics_measurement_id);

  function scrollToSection() {
    const sectionElement = document.getElementById("body-story");
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
    }
  }

  function scrollToStories() {
    const sectionElement = document.getElementById("stories-chapter");
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
    }
  }

  function renderInsights() {
    //console.log("RENDER INSIGHTS SI E' RIPETUTO");
    return (
      <>
        <List
          style={{ color: "white", marginBottom: "20px" }}
          className="left-menu"
        >
          <ListItem disablePadding>
            <ListItemButton onClick={scrollToStories}>
              <ListItemIcon>
                <AutoStoriesIcon style={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText primary={t("storiesChapter")} />
            </ListItemButton>
          </ListItem>
        </List>
      </>
    );
  }

  function renderButtons() {
    return (
      <>
        <div className="footer-story-buttons">
          <div className="button-previous-story">
            <Button
              variant="outlined"
              disabled={state.previousChapter == null}
              sx={{ color: "white" }}
            >
              <Link
                to={
                  state.previousChapter != null &&
                  `/chapters/chapter/${state.previousChapter}/${state.previousChapterUrl}`
                }
                onClick={() => fetchChapterAndStories(state.previousChapter)}
                className="link-buttons"
              >
                {isDesktopScreen ? (
                  <>
                    <ArrowCircleLeftIcon />‎ ‎‎ ‎ ‎ {t("previousChapter")}
                  </>
                ) : (
                  <>
                    <ArrowCircleLeftIcon />‎ ‎‎ ‎ ‎{" "}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {t("previousChapterMobile")}
                    </div>
                  </>
                )}
              </Link>
            </Button>
          </div>
          <div className="button-next-story">
            <Button
              variant="outlined"
              disabled={state.nextChapter == null}
              sx={{ color: "white" }}
            >
              <Link
                to={
                  state.nextChapter != null &&
                  `/chapters/chapter/${state.nextChapter}/${state.nextChapterUrl}`
                }
                onClick={() => fetchChapterAndStories(state.nextChapter)}
                className="link-buttons"
              >
                {isDesktopScreen ? (
                  <>
                    {t("nextChapter")}‎ ‎‎ ‎ ‎<ArrowCircleRightIcon />
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {t("nextChapterMobile")}
                    </div>
                    ‎ ‎‎ ‎ ‎<ArrowCircleRightIcon />
                  </>
                )}
              </Link>
            </Button>
          </div>
        </div>
      </>
    );
  }

  // function renderSuggestedStories() {
  //   return (
  //     <div className="side-stories-container">
  //       <p
  //         style={{
  //           fontSize: "16px",
  //           color: "white",
  //           fontWeight: "bold",
  //           marginBottom: "20px",
  //         }}
  //       >
  //         {t("otherStories")}
  //       </p>
  //       {state.storiesProposed?.map((story, key) => (
  //         <Grid item xs={12} sm={6} md={3} key={key} className="card-grid">
  //           <CustomCard
  //             content={story}
  //             maxPicHeight="100px"
  //             minWidth="25vw"
  //             withText={false}
  //             url={`/stories/story/${story.idDocument}`}
  //             refetchFunction={() => fetchStory(story.idDocument)}
  //           />
  //         </Grid>
  //       ))}
  //     </div>
  //   );
  // }
  async function fetchChapterAndStories(idDocument) {
    scrollToStartSection();
    let chapterId = idDocument != null ? idDocument : id.split("/").pop();

    try {
      let chapterData = await getChapterById(chapterId);

      ReactGA.send({
        hitType: "pageview",
        page: "/chapters/chapter",
        title: `Chapter view: ${chapterData.titleIt}`,
      });

      let currentChapterUrlIt = chapterData.urlIt;
      let currentChapterUrlEn = chapterData.urlEn;
      //console.log("(VIEW) Story data fetched: ", chapterData);

      let chaptersJson = await checkChaptersSessionStorage();
      let chaptersProposed = storiesChapterInsights(
        chaptersJson.chapters,
        chapterData
      );

      let buttonChapters = findAdjacentChapters(
        chaptersJson.chapters,
        chapterData
      );

      const dataFromJson = await checkStoriesSessionStorage();
      const storiesOfTheChapter = dataFromJson.stories.filter(
        (story) => story.chapter === chapterData.code
      );
      console.log("STORIE DEL CAPITOLO:", storiesOfTheChapter);

      setState({
        ...state,
        chapterObj: chapterData,
        chaptersJson: chaptersJson,
        chaptersProposed: chaptersProposed,
        previousChapter: buttonChapters[0].previousChapterId,
        previousChapterUrl:
          i18n.language == "it"
            ? buttonChapters[0].previousChapterUrlIt
            : buttonChapters[0].previousChapterUrlEn,
        nextChapter: buttonChapters[1].nextChapterId,
        nextChapterUrl:
          i18n.language == "it"
            ? buttonChapters[1].nextChapterUrlIt
            : buttonChapters[1].nextChapterUrlEn,
        idChapterFromUrl: chapterId,
        chapterUrlIt: currentChapterUrlIt,
        chapterUrlEn: currentChapterUrlEn,
        jsonStories: storiesOfTheChapter,
        isFirstRender: true,
      });
    } catch (error) {
      console.error("Error fetching chapter:", error);
    }
  }

  function VideoPlayer({ videoUrl, previewImageUrl }) {
    return (
      <div className="video-desktop">
        <video
          controls
          controlsList="nodownload"
          width={isSmallScreen && !isRotated ? "90%" : "50%"}
          poster={previewImageUrl}
          style={{ borderRadius: "15px", marginBottom: "40px" }}
        >
          <source src={videoUrl} type="video/mp4" />
        </video>
      </div>
    );
  }

  function mapStories() {
    return (
      <div className="card-container" style={{ minWidth: "90%" }}>
        {state.jsonStories
          ?.slice(state.startPagination, state.endPagination)
          .map((story, key) => (
            <CustomCard
              content={story}
              withText={true}
              maxWidth={isReallyMobileScreen ? "100%" : "400px"}
              url={`/stories/story/${story.idDocument}/${
                i18n.language == "it" ? story.urlIt : story.urlEn
              }`}
            />
          ))}
        <div style={{ width: "100%", justifyContent: "center" }}>
          {!state.jsonStories && (
            <>
              <SkeletonCardsGrid direction={"row"} />{" "}
              <SkeletonCardsGrid direction={"row"} />
            </>
          )}
          {state.jsonStories?.slice(state.startPagination, state.endPagination)
            .length <= 0 && (
            <p className="no-story-found">{t("noStoryFound")}</p>
          )}
        </div>
      </div>
    );
  }

  function setPage(page) {
    console.log("Da positzione: ", page * 12 - 12, " a positione: ", page * 12);
    setState({
      ...state,
      startPagination: page * 12 - 12,
      endPagination: page * 12,
      currentPage: page,
    });
  }

  function setUrl() {
    const fullURL = window.location.href;
    const segments = fullURL.split("/");
    const urlWithoutLangUrl = segments.slice(0, 6).join("/");
    const newPath =
      i18n.language === "it" ? state.chapterUrlIt : state.chapterUrlEn;
    console.log("New Path:", newPath);
    console.log("URL without Language URL:", urlWithoutLangUrl);
    window.history.pushState({}, "", `${urlWithoutLangUrl}/${newPath}`);
  }

  useEffect(() => {
    fetchChapterAndStories();
    console.log("18n.language", i18n.language);
  }, []);

  useEffect(() => {
    if (state.isFirstRender) {
      setUrl();
    }
  }, [i18n.language]);

  // useEffect(() => {
  //   console.log("Navigation triggered");
  //   let path = `/chapters/chapter/${state.idChapterFromUrl}/${
  //     i18n.language === "it" ? state.chapterUrlIt : state.chapterUrlEn
  //   }`;
  //   navigate(path);
  // }, [
  //   i18n.language,
  //   state.idChapterFromUrl,
  //   state.chapterUrlIt,
  //   state.chapterUrlEn,
  //   navigate,
  // ]);

  return (
    <div id="start">
      {state.chapterObj && (
        <div>
          {state.chapterObj?.urlWebImage ? (
            <img
              src={state.chapterObj?.urlWebImage}
              className="background-picture"
            ></img>
          ) : (
            <Skeleton
              sx={{ bgcolor: "grey.900", height: "100%" }}
              variant="rectangular"
              className="background-picture"
            />
          )}

          <div className="dark-shade"></div>
          <div className="dark-shade-bottom-up"></div>
          <div className="dark-shade-bottom-down"></div>
          <div className="main-container-component">
            {isDesktopScreen && (
              <>
                {state.chapterObj?.titleIt && state.chapterObj?.titleEn ? (
                  <div className="title shade-title" style={{ zIndex: 1 }}>
                    {i18n.language == "it" && state.chapterObj?.titleIt}
                    {i18n.language == "en" && state.chapterObj?.titleEn}
                  </div>
                ) : (
                  <Skeleton
                    variant="rounded"
                    height={100}
                    sx={{
                      width: "50%",
                      zIndex: 1,
                      marginTop: "150px",
                    }}
                  />
                )}
                {state.chapterObj != null && (
                  <VideoPlayer
                    videoUrl={
                      i18n.language == "it"
                        ? state.chapterObj.urlVideoIt
                        : state.chapterObj.urlVideoEn
                    }
                    previewImageUrl={state.chapterObj.urlWebImage}
                  />
                )}
              </>
            )}
            {!isDesktopScreen && (
              <div
                style={{
                  zIndex: 1,
                  height: "90vh",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                {state.chapterObj?.titleIt && state.chapterObj?.titleEn ? (
                  <div
                    className="title shade-title"
                    style={{
                      zIndex: 1,
                      marginTop: "-30vh",
                      textAlign: "center",
                      marginTop: {
                        if(isSmallScreen) {
                          "15px";
                        },
                        if(isRotated) {
                          "25px";
                        },
                      },
                    }}
                  >
                    {i18n.language == "it" && state.chapterObj?.titleIt}
                    {i18n.language == "en" && state.chapterObj?.titleEn}
                  </div>
                ) : (
                  <Skeleton
                    variant="rounded"
                    height={100}
                    sx={{
                      width: "50%",
                      zIndex: 1,
                      marginTop: "150px",
                    }}
                  />
                )}
                {state.chapterObj != null && (
                  <VideoPlayer
                    videoUrl={state.chapterObj.urlVideoIt}
                    previewImageUrl={state.chapterObj.urlWebImage}
                  />
                )}
                {!isRotated && (
                  <>
                    <button
                      onClick={scrollToSection}
                      className="title shade-title"
                      style={{
                        fontSize: "15px",
                        zIndex: 999,
                        marginTop: "-20px",
                      }}
                    >
                      {t("scrollDownToReadChapter")}
                    </button>
                    <ExpandCircleDownIcon
                      onClick={scrollToSection}
                      style={{
                        color: "white",
                        marginTop: "-5vh",
                        fontSize: "50px",
                      }}
                    />
                  </>
                )}
              </div>
            )}
          </div>
          <div
            className={
              isLittleMobileScreen
                ? "story-container-mobile"
                : "story-container"
            }
            style={{
              marginTop: {
                if(isRotated) {
                  "-50px";
                },
              },
            }}
          >
            <div className="story-left-part" id="body-story">
              <Item elevation={24} style={{ height: "auto" }}>
                {i18n.language == "en" && state.chapterObj?.urlMarkdownEn && (
                  <MarkDownReader markdown={state.chapterObj?.urlMarkdownEn} />
                )}
                {i18n.language == "it" && state.chapterObj?.urlMarkdownIt && (
                  <MarkDownReader markdown={state.chapterObj?.urlMarkdownIt} />
                )}
              </Item>
            </div>
            {isDesktopScreen && (
              <div className="story-right-part">
                <div style={{ height: "30vh", paddingLeft: "20px" }}>
                  {renderInsights()}
                  {/*renderSuggestedStories()*/}
                </div>
              </div>
            )}
          </div>
          {!isDesktopScreen && (
            <div className="footer-stories-container">
              {" "}
              <Accordion style={{ width: "90vw" }}>
                <AccordionSummary
                  expandIcon={<ExpandCircleDownIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    {t("insights")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{renderInsights()}</Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          )}
          <div style={{ marginBottom: "50px" }}>{renderButtons()}</div>
          {!isDesktopScreen && (
            <div className="footer-stories-container">
              <p
                style={{
                  fontSize: "16px",
                  color: "white",
                  fontWeight: "bold",
                  marginBottom: "20px",
                }}
              >
                {t("relatedPosts")}
              </p>
              <div className="side-stories-container">
                {/* {state.articlesProposed?.map((story, key) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    key={key}
                    className="card-grid"
                  >
                    <CustomCard
                      content={story}
                      maxPicHeight="100px"
                      maxWidth="90vw"
                      minWidth="90vw"
                      withText={false}
                      url={`/stories/story/${story.idDocument}`}
                      refetchFunction={() => fetchStory(story.idDocument)}
                    />
                  </Grid>
                ))} */}
              </div>
            </div>
          )}
          <div className="stories-grid-chapter" id="stories-chapter">
            <div className="stories-chapter">{t("storiesOfChapter")}</div>
            {mapStories()}
            <CustomPagination
              setPagination={setPage}
              storiesLength={state.jsonStories?.length}
              pageImposed={state.currentPage}
            />
          </div>
        </div>
      )}
    </div>
  );
}
