// import React, { useEffect, useRef, useState } from "react";
// import { Virtual, Navigation, Pagination, FreeMode } from "swiper/modules";
// import { Swiper, SwiperSlide } from "swiper/react";

// // Import Swiper styles
// import "swiper/css";
// import "swiper/css/pagination";
// import "swiper/css/navigation";
// import "./carousel.css";

// import Image1 from "../../assets/images/image-home.png";

// import { useMediaQuery } from "@mui/material";
// import { useTranslation } from "react-i18next";
// import { convertFormatTimestamp } from "../../utils/dataUtils";
// import { Link } from "react-router-dom";

// export default function Carousel(props) {
//   const isMobileScreen = useMediaQuery("(max-width:600px)");
//   const isMobileMiddleScreen = useMediaQuery("(max-width:900px)");
//   const isScreen1050 = useMediaQuery("(max-width:1050px)");
//   const { t, i18n } = useTranslation();

//   const [state, setState] = useState({
//     URL: "",
//   });

//   function setUrl() {
//     let link = "";
//     if (props.type === "posts") {
//       link = "/blog/blogpost/";
//     } else if (props.type === "stories") {
//       link = "/stories/story/";
//     }
//     setState({
//       ...state,
//       URL: link,
//     });
//   }

//   useEffect(() => {
//     setUrl();
//   }, []);

//   return (
//     <div className="flex items-center justify-center flex-col bg-transparent p-4">
//       {!isMobileScreen && (
//         <Swiper
//           modules={[Virtual, Navigation, Pagination]}
//           slidesPerView={3}
//           spaceBetween={30}
//           pagination={{
//             clickable: true,
//           }}
//           navigation={isMobileMiddleScreen ? false : true}
//           virtual
//           className="max-w-[95%] lg:max-w-[95%]"
//         >
//           {props.data?.map((item) => (
//             <SwiperSlide key={item.title}>
//               <div className="flex flex-col gap-6 mb-20 group relative shadow-lg text-white rounded-xl px-6 py-8 h-[250px] w-[215px] lg:h-[400px] lg:w-[350px] overflow-hidden cursor-pointer">
//                 <Link
//                   to={`${state.URL}${item.idDocument}/${
//                     i18n.language == "it" ? item.urlIt : item.urlEn
//                   }`}
//                 >
//                   <div
//                     className="absolute inset-0 bg-cover bg-center"
//                     style={{ backgroundImage: `url(${item.mobileImage})` }}
//                   />
//                   <div className="absolute inset-0 bg-black opacity-10 group-hover:opacity-50" />
//                   <div className="relative flex flex-col gap-3">
//                     <h1
//                       className="title-card"
//                       style={{
//                         fontSize: !isScreen1050 ? "30px" : "20px",
//                       }}
//                     >
//                       {i18n.language == "it" ? item.titleIt : item.titleEn}{" "}
//                     </h1>
//                     {props.type === "posts" && (
//                       <p
//                         className="publication-card"
//                         style={{
//                           fontSize: !isScreen1050 ? "20px" : "15px",
//                         }}
//                       >
//                         {t("publicationDate") +
//                           "  " +
//                           convertFormatTimestamp(item.timestamp)}
//                       </p>
//                     )}
//                     {!isMobileMiddleScreen && (
//                       <p className="text-card">
//                         {i18n.language == "it"
//                           ? item.descriptionIt
//                           : item.descriptionEn}{" "}
//                       </p>
//                     )}
//                   </div>
//                 </Link>
//               </div>
//             </SwiperSlide>
//           ))}
//         </Swiper>
//       )}
//       {isMobileScreen && (
//         <Swiper
//           modules={[FreeMode, Navigation, Pagination]}
//           slidesPerView={1.5}
//           spaceBetween={30}
//           freeMode={true}
//           pagination={{
//             clickable: true,
//           }}
//           className="max-w-[90%] lg:max-w-[90%]"
//         >
//           {props.data?.map((item) => (
//             <SwiperSlide key={item.title}>
//               <div className="flex flex-col gap-6 mb-20 group relative shadow-lg text-white rounded-xl px-6 py-8 h-[250px] w-[215px] lg:h-[400px] lg:w-[350px] overflow-hidden cursor-pointer">
//                 <Link
//                   to={`${state.URL}${item.idDocument}/${
//                     i18n.language == "it" ? item.urlIt : item.urlEn
//                   }`}
//                 >
//                   <div
//                     className="absolute inset-0 bg-cover bg-center"
//                     style={{ backgroundImage: `url(${item.mobileImage})` }}
//                   />
//                   <div className="absolute inset-0 bg-black opacity-10 group-hover:opacity-50" />
//                   <div className="relative flex flex-col gap-3">
//                     <h1 className="title-card">
//                       {i18n.language == "it" ? item.titleIt : item.titleEn}{" "}
//                     </h1>
//                     {props.type === "posts" && (
//                       <p
//                         className="publication-card"
//                         style={{
//                           fontSize: "15px",
//                         }}
//                       >
//                         {convertFormatTimestamp(item.timestamp)}
//                       </p>
//                     )}
//                   </div>
//                 </Link>
//               </div>
//             </SwiperSlide>
//           ))}
//         </Swiper>
//       )}
//     </div>
//   );
// }

import React, { useEffect, useState } from "react";
import { Virtual, Navigation, Pagination, FreeMode } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./carousel.css";

import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { convertFormatTimestamp } from "../../utils/dataUtils";
import { Link } from "react-router-dom";

export default function Carousel(props) {
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const isMobileMiddleScreen = useMediaQuery("(max-width:900px)");
  const isScreen1050 = useMediaQuery("(max-width:1050px)");
  const { t, i18n } = useTranslation();

  const [state, setState] = useState({
    URL: "",
  });

  function setUrl() {
    let link = "";
    if (props.type === "posts") {
      link = "/blog/blogpost/";
    } else if (props.type === "stories") {
      link = "/stories/story/";
    }
    setState({
      ...state,
      URL: link,
    });
  }

  useEffect(() => {
    setUrl();
  }, []);

  const getSlidesPerView = () => {
    if (isMobileScreen) {
      return 1; // Show one slide at a time for better spacing on mobile
    } else if (props.data?.length >= 3) {
      return 3;
    } else {
      return props.data?.length || 1;
    }
  };

  const getSpaceBetween = () => {
    if (isMobileScreen) {
      return 15; // Adjust this value for better spacing on mobile
    } else {
      return 30;
    }
  };

  return (
    <div className="flex items-center justify-center flex-col bg-transparent p-4">
      <Swiper
        modules={[Virtual, Navigation, Pagination, FreeMode]}
        slidesPerView={getSlidesPerView()}
        spaceBetween={getSpaceBetween()}
        pagination={{
          clickable: true,
        }}
        navigation={!isMobileScreen}
        freeMode={isMobileScreen}
        virtual
        className="max-w-[95%] lg:max-w-[95%]"
      >
        {props.data?.map((item) => (
          <SwiperSlide key={item.title}>
            <div
              className={`flex flex-col gap-6 mb-20 group relative shadow-lg text-white rounded-xl px-6 py-8 ${
                isMobileScreen
                  ? "h-[250px] w-[90vw]"
                  : "h-[250px] w-[215px] lg:h-[400px] lg:w-[350px]"
              } overflow-hidden cursor-pointer`}
            >
              <Link
                to={`${state.URL}${item.idDocument}/${
                  i18n.language === "it" ? item.urlIt : item.urlEn
                }`}
              >
                <div
                  className="absolute inset-0 bg-cover bg-center"
                  style={{ backgroundImage: `url(${item.mobileImage})` }}
                />
                <div className="absolute inset-0 bg-black opacity-10 group-hover:opacity-50" />
                <div className="relative flex flex-col gap-3">
                  <h1
                    className="title-card"
                    style={{
                      fontSize: !isScreen1050 ? "30px" : "20px",
                    }}
                  >
                    {i18n.language === "it" ? item.titleIt : item.titleEn}{" "}
                  </h1>
                  {props.type === "posts" && (
                    <p
                      className="publication-card"
                      style={{
                        fontSize: !isScreen1050 ? "20px" : "15px",
                      }}
                    >
                      {t("publicationDate") +
                        "  " +
                        convertFormatTimestamp(item.timestamp)}
                    </p>
                  )}
                  {!isMobileMiddleScreen && (
                    <p className="text-card">
                      {i18n.language === "it"
                        ? item.descriptionIt
                        : item.descriptionEn}{" "}
                    </p>
                  )}
                </div>
              </Link>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
