import React, { useState, useEffect } from "react";
import Markdown from "react-markdown";
import "./markdownreader.css";
import termsFrPath from "./test.md";

function MarkDownReader(props) {
  const [terms, setTerms] = useState(null);

  async function fetchTerms() {
    try {
      const response = await fetch(props.markdown);
      console.log("Markdown fetched: ", response);
      const text = await response.text();
      setTerms(text);
    } catch (error) {
      console.error("Error fetching terms:", error);
    }
  }

  useEffect(() => {
    fetchTerms();
  }, [props.markdown]);

  return (
    <div>
      <Markdown className="markdown content">{terms}</Markdown>
    </div>
  );
}

export default MarkDownReader;
