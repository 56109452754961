import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useScreenRotation, { scrollToStartSection } from "../utils/uiUtils";

// css
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "../styles/home.css";
import "../styles/general.css";
import "../components/HeaderBar/headerbar.css";

// components
import Navbar from "../components/HeaderBar/Navbar";
import ParallaxGsap from "../components/Parallax/ParallaxGsap";
import Carousel from "../components/Carousel/Carousel";
import AnimatedLogo from "../components/AnimatedLogo/AnimatedLogo";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

import { useMediaQuery } from "@mui/material";
import { checkStoriesSessionStorage } from "../utils/firebaseUtils";
import { checkPostsSessionStorage } from "../utils/blogFirebaseUtils";
import { videoUrlHomeIt, videoUrlHomeEn } from "../utils/dataUtils";
import imageHome from "../assets/images/image-home.png";
import Map from "../components/Map/Map";
import ReactGA from "react-ga4";
import { google_analytics_measurement_id } from "../utils/dataUtils";

export default function Home(props) {
  ReactGA.initialize(google_analytics_measurement_id);
  ReactGA.send({ hitType: "pageview", page: "/home", title: "Home view" });

  const [state, setState] = useState({
    ordersList: null,
    mostRecentOrders: [],
  });

  const isDesktopScreen = useMediaQuery("(min-width:1025px)");
  const isSmallScreen = useMediaQuery("(max-width:900px)");
  const isMobileScreen = useMediaQuery("(max-width:500px)");
  const isRotated = useScreenRotation();
  const { t, i18n } = useTranslation();

  const markdown = "# Hi, *Pluto*!";

  async function fetchJsonPostsAndStories() {
    scrollToStartSection();
    const dataFromJsonPosts = await checkPostsSessionStorage();
    let mostRecentPosts = [];
    if (dataFromJsonPosts.posts.length >= 10) {
      mostRecentPosts = dataFromJsonPosts.posts.slice(0, 10);
    } else {
      mostRecentPosts = dataFromJsonPosts.posts;
    }
    console.log("POSTS FROM JSON :", mostRecentPosts);
    const dataFromJsonStories = await checkStoriesSessionStorage();
    let mostRecentStories = [];
    if (dataFromJsonStories.stories.length >= 10) {
      mostRecentStories = dataFromJsonStories.stories.slice(0, 10);
    } else {
      mostRecentStories = dataFromJsonStories.stories;
    }
    console.log("STORIES FROM JSON :", mostRecentStories);

    setState({
      ...state,
      jsonPosts: mostRecentPosts,
      jsonStories: mostRecentStories,
      allJsonStories: dataFromJsonStories,
    });
  }

  function genericButton(url, title) {
    return (
      <div className="button-container">
        <Button
          variant="contained"
          sx={{
            color: "white",
            height: "50px",
            width: isMobileScreen ? "90%" : "40%",
          }}
        >
          <Link to={url} className="link-buttons">
            <div className="button-text">{t(title)}</div>
          </Link>
        </Button>
      </div>
    );
  }

  function VideoPlayer({ videoUrl, previewImageUrl }) {
    return (
      <div className="video-desktop-home">
        <video
          controls
          controlsList="nodownload"
          width={isSmallScreen && !isRotated ? "90%" : "50%"}
          poster={previewImageUrl}
          style={{
            borderRadius: "15px",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        >
          <source src={videoUrl} type="video/mp4" />
        </video>
      </div>
    );
  }

  useEffect(() => {
    fetchJsonPostsAndStories();
  }, []);

  return (
    <div className="bg">
      {isDesktopScreen && <ParallaxGsap />}
      {!isDesktopScreen && !isRotated && (
        <div className="background h-screen flex flex-col justify-center items-center">
          <div className="background-image-mobile">
            <Navbar />
            <div className="flex flex-col flex-1 items-center mt-[18vh]">
              <div
                className="main-title"
                style={{ marginTop: 0, marginBottom: 15 }}
              >
                <h1 className="text-4xl text-white font-thin">
                  {t("landsOfTales")}
                </h1>
              </div>
              <AnimatedLogo customClass={"logo-mobile-parallax"} />
            </div>
          </div>
        </div>
      )}
      {!isDesktopScreen && isRotated && (
        <div className="background h-screen flex flex-col items-center">
          <div className="background-image-mobile">
            <Navbar />
            <div className="flex flex-col flex-1 items-center">
              <div
                className="main-title"
                style={{ marginTop: -35, marginBottom: 5 }}
              >
                <h1 className="text-4xl text-white font-thin">
                  {t("landsOfTales")}
                </h1>
              </div>
              <AnimatedLogo customClass={"logo-mobile-parallax"} />
            </div>
          </div>
        </div>
      )}

      <div className="description-container">
        <div
          className="title"
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
            marginTop: "25px",
            width: "90%",
            fontSize: isMobileScreen ? "20px" : "45px",
          }}
        >
          {t("whatIsLandsOfTales")}
        </div>
        <p className={isMobileScreen ? "description-mobile" : "description"}>
          {t("landsOfTalesDescriptionP1")}
        </p>
        <p className={isMobileScreen ? "description-mobile" : "description"}>
          {t("landsOfTalesDescriptionP2")}
        </p>
      </div>
      <div style={{ marginTop: "50px" }}>
        {
          <VideoPlayer
            videoUrl={i18n.language == "it" ? videoUrlHomeIt : videoUrlHomeEn}
            previewImageUrl={imageHome}
          />
        }
      </div>
      <div
        className="title"
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "-20px",
          marginTop: "25px",
        }}
      >
        {t("recentPosts")}
      </div>
      <Carousel data={state.jsonPosts} type={"posts"} />
      {genericButton("/blog/", "morePosts")}
      <div
        className="title"
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "-20px",
          marginTop: "0px",
        }}
      >
        {t("recentStories")}
      </div>
      <Carousel data={state.jsonStories} type={"stories"} />
      <div className="map-container">
        <div style={{ width: "80%" }}>
          <Map
            zoom={10}
            maxZoom={16}
            stories={state.allJsonStories?.stories}
            height={"70vh"}
            withCards={true}
          />
        </div>
      </div>
      {genericButton("/stories/", "moreStories")}
      {/* <Map props={50} zoom={10} maxZoom={20} /> */}
    </div>
  );
}
