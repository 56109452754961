import "./map.css";
import "leaflet/dist/leaflet.css";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Tooltip,
  useMap,
} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { Icon, divIcon, point } from "leaflet";
import Pin from "../../assets/images/map/placeholder.png";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import CustomCard from "../Card/CustomCard";

const customIcon = new Icon({
  iconUrl: require("../../assets/images/map/pin.png"),
  iconSize: [45, 45],
  iconAnchor: [19, 45],
});

const createClusterCustomIcon = function (cluster) {
  return new divIcon({
    html: `<span class="cluster-icon">${cluster.getChildCount()}</span>`,
    className: "custom-marker-cluster",
    iconSize: point(33, 33, true),
  });
};

export default function Map(props) {
  const [state, setState] = useState({
    // latitude: props?.stories[0]?.geocode?.[0] || 0, // Use first story's latitude or default to 0
    // longitude: props?.stories[0]?.geocode?.[1] || 0, // Use first story's longitude or default to 0
    latitude: 0, // Use first story's latitude or default to 0
    longitude: 0, // Use first story's longitude or default to 0
    locationsArray: [],
  });

  const { t, i18n } = useTranslation();

  function centerCoordX(listOfStories) {
    let min = 90;
    let max = 0;

    if (listOfStories.length > 1) {
      listOfStories.forEach((marker) => {
        if (min >= marker?.longitude) {
          min = marker?.longitude;
        }
        if (max <= marker?.longitude) {
          max = marker?.longitude;
        }
      });

      return parseFloat(min) + parseFloat(max - min) / 2;
    } else {
      return listOfStories[0]?.longitude;
    }
  }

  function centerCoordY(listOfStories) {
    let min = 180;
    let max = 0;

    if (listOfStories.length > 1) {
      listOfStories.forEach((marker) => {
        if (min >= marker?.latitude) {
          min = marker?.latitude;
        }
        if (max <= marker?.latitude) {
          max = marker?.latitude;
        }
      });

      return parseFloat(min) + parseFloat(max - min) / 2;
    } else {
      return listOfStories[0]?.latitude;
    }
  }

  function setCenterCoordinates(stories) {
    // const firstStory = stories[0];
    // setState({
    //   ...state,
    //   latitude: firstStory?.geocode?.[0] || 0,
    //   longitude: firstStory?.geocode?.[1] || 0,
    //   locationsArray: stories,
    // });

    console.log("LOCATIONSARRAY :", stories);
    setState({
      ...state,
      latitude: centerCoordY(stories),
      longitude: centerCoordX(stories),
      locationsArray: stories,
    });
  }

  function MyComponent(props) {
    const map = useMap();
    map.setView([state.latitude, state.longitude], props.zoom);
    return null;
  }

  // useEffect(() => {
  //   if (props.stories && props.stories.length > 0) {
  //     try {
  //       setCenterCoordinates(props.stories); // Pass props.stories to the function
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   }
  // }, [props.stories]);
  useEffect(() => {
    if (props.stories && props.stories.length > 0) {
      setCenterCoordinates(props.stories);
    } else {
      setState((prevState) => ({
        ...prevState,
        locationsArray: [],
      }));
    }
  }, [props.stories]);

  return (
    <div className="map-container">
      {state.latitude != 0 && state.longitude != 0 && (
        <MapContainer
          center={[state.latitude, state.longitude]}
          zoom={props.zoom}
          // style={{ height: "30vh" }}
          style={{ height: props.height }}
        >
          <MyComponent />
          <TileLayer
            attribution="Google Maps"
            url="http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}"
            maxZoom={props.maxZoom}
            subdomains={["mt0", "mt1", "mt2", "mt3"]}
          />

          <MarkerClusterGroup
            chunkedLoading
            iconCreateFunction={createClusterCustomIcon}
          >
            {state.locationsArray.map((marker, index) => (
              <Marker
                position={[marker.latitude, marker.longitude]}
                icon={customIcon}
                key={index}
              >
                <Tooltip>
                  <div className="text-popup">{t("clickOnThePin")}</div>
                </Tooltip>
                <Popup>
                  {props.withCards && (
                    <div style={{ marginBottom: "20px" }}>
                      <CustomCard
                        content={marker}
                        maxPicHeight="100px"
                        minWidth="25vw"
                        withText={false}
                        url={`/stories/story/${marker.idDocument}/${
                          i18n.language == "it" ? marker.urlIt : marker.urlEn
                        }`}
                      />
                    </div>
                  )}
                  <a
                    href={`https://www.google.com/maps?q=${marker.latitude},${marker.longitude}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-popup"
                  >
                    {t("openGoogleMaps")}
                  </a>
                </Popup>
              </Marker>
            ))}
          </MarkerClusterGroup>
        </MapContainer>
      )}
    </div>
  );
}
