import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Item = ({ Links, title }) => {
  const { t } = useTranslation();

  return (
    <ul>
      <h1 className="mb-1 font-semibold">{t(title)}</h1>
      {Links.map((link) => (
        <Link to={link.link}>
          <li key={link.name}>
            <div
              className="text-gray-400 hover:text-teal-400 duration-300
          text-sm cursor-pointer leading-6"
            >
              {t(link.name)}
            </div>
          </li>
        </Link>
      ))}
    </ul>
  );
};

export default Item;
