import Item from "./Item";
import { ABOUT, CONTENTS } from "./Menus";
import AnimatedLogo from "../AnimatedLogo/AnimatedLogo";
import { useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";

function ItemsContainer() {
  const isDesktopScreen = useMediaQuery("(min-width:650px)");
  const isMobileScreen = useMediaQuery("(max-width:650px)");

  return (
    <>
      {isDesktopScreen && (
        <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-6 sm:px-8 px-5 py-16 ">
          {/* <Item Links={PRODUCTS} title="" />
      <Item Links={RESOURCES} title="RESOURCES" /> */}
          <div className="flex justify-center items-center">
            <Item Links={ABOUT} title="whoWeAre" />
          </div>
          <div className="flex justify-center items-center">
            <Link to="/">
              <AnimatedLogo customClass={"logo"} />
            </Link>
          </div>
          <div className="flex justify-center items-center">
            <Item Links={CONTENTS} title="contents" />
          </div>
        </div>
      )}
      {isMobileScreen && (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6 sm:px-8 px-5 py-16 ">
          {/* <Item Links={PRODUCTS} title="" />
      <Item Links={RESOURCES} title="RESOURCES" /> */}
          <div className="flex flex-col justify-center items-center">
            <div className="flex flex-col justify-center space-y-8">
              <Item Links={ABOUT} title="whoWeAre" />
              <Item Links={CONTENTS} title="contents" />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ItemsContainer;
