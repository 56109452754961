

import Stripe from "stripe";
import { Functions } from "@firebase/functions";
import { getFunctions, httpsCallable } from "firebase/functions";
import { createStripeCheckout } from "../firebase";
import axios from "axios";
import { getAuth } from "firebase/auth";

const cloudFunctionUrl =
  "https://us-central1-lorelandwebsite.cloudfunctions.net/createStripeCheckout";

const checkoutButton = document.getElementById("checkout-button");
const stripe = Stripe(
  "pk_test_51NtrvoJVQTiIlH2EKD8JWHTSTSzJLFBcIYSj934XfrLRJAx4Dtu0Fc775MHTI01YL5UlqsaCPSKWkan6vvQOOtHP00q0h5c25b"
);

export const checkOut1 = async () => {
  try {
    const stripeApiKey =
      "pk_test_51NtrvoJVQTiIlH2EKD8JWHTSTSzJLFBcIYSj934XfrLRJAx4Dtu0Fc775MHTI01YL5UlqsaCPSKWkan6vvQOOtHP00q0h5c25b"; // Replace with your Stripe publishable key
      const user = getAuth().currentUser;
      const userId = user.uid;
      console.log("userId", userId);
    const response = await axios.post(
      "https://us-central1-lorelandwebsite.cloudfunctions.net/createStripeCheckout",
      {userId},
      {
        headers: {
          Authorization: `Bearer ${stripeApiKey}`,
        },
      }
    );
    console.log("session ", response.data.session);
    console.log("response ", response.data.session.url);
    console.log("stripeApiKey ", stripeApiKey, response.url);

    // Check if the response contains a valid session ID
    if (response.data.session.url) {
      // Redirect the user to the checkout page.
      window.location.href =  response.data.session.url;

    } else {
      console.error("Invalid response from the server:", response);
    }
  } catch (error) {
    console.error("Error calling checkout1 function:", error);
  }
};


