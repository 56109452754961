import React, { useEffect, useState } from "react";
import "../styles/general.css";
import "../styles/storydetail.css";
import { useTranslation } from "react-i18next";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import ShieldIcon from "@mui/icons-material/Shield";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import Skeleton from "@mui/material/Skeleton";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  styled,
  useMediaQuery,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";

// Components
import Map from "../components/Map/Map";
import MarkDownReader from "../components/MarkDownReader/MarkDownReader";
import CustomCard from "../components/Card/CustomCard";
import {
  checkChaptersSessionStorage,
  checkStoriesSessionStorage,
  getStoryById,
} from "../utils/firebaseUtils";
import {
  chapterOfCurrentStory,
  findAdjacentStories,
  google_analytics_measurement_id,
  storiesChapterInsights,
} from "../utils/dataUtils";
import useScreenRotation, { scrollToStartSection } from "../utils/uiUtils";
import ReactGA from "react-ga4";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: 60,
  lineHeight: "60px",
}));

export default function StoryDetail(props) {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const isDesktopScreen = useMediaQuery("(min-width:1025px)");
  const isLittleMobileScreen = useMediaQuery("(max-width:500px)");
  const isRotated = useScreenRotation();
  const navigate = useNavigate();

  const [state, setState] = useState({
    storyObj: {},
    storyLocation: [],
    idStoryFromUrl: "",
    imageLoaded: false,
    isFirstRender: false,
  });

  ReactGA.initialize(google_analytics_measurement_id);

  useEffect(() => {
    fetchStory();
    console.log("i18n.language", i18n.language);
  }, []);

  useEffect(() => {
    if (state.isFirstRender) {
      setUrl();
    }
    //updateMetaTags(state.storyObj);
  }, [i18n.language]);

  // const updateMetaTags = (storyData) => {
  //   document
  //     .querySelector('meta[property="og:title"]')
  //     .setAttribute(
  //       "content",
  //       i18n.language === "it" ? storyData.titleIt : storyData.titleEn
  //     );
  //   document
  //     .querySelector('meta[property="og:description"]')
  //     .setAttribute(
  //       "content",
  //       i18n.language === "it"
  //         ? storyData.descriptionIt
  //         : storyData.descriptionEn
  //     );
  //   document
  //     .querySelector('meta[property="og:image"]')
  //     .setAttribute("content", storyData.urlWebImage);
  //   document
  //     .querySelector('meta[property="og:url"]')
  //     .setAttribute("content", window.location.href);
  // };

  async function fetchStory(idDocument) {
    scrollToStartSection();
    let storyId = idDocument != null ? idDocument : id.split("/").pop();
    try {
      let storyData = await getStoryById(storyId);
      console.log("(VIEW) Story data fetched: ", storyData);

      ReactGA.send({
        hitType: "pageview",
        page: "/stories/story",
        title: `Story view: ${storyData.titleIt}`,
      });

      let storyLocationArray = [
        {
          latitude: storyData?.location?.latitude,
          longitude: storyData?.location?.longitude,
        },
      ];

      let currentStoryUrlIt = storyData.urlIt;
      let currentStoryUrlEn = storyData.urlEn;

      let storiesJson = await checkStoriesSessionStorage();
      let chaptersFromJson = await checkChaptersSessionStorage();

      let chapterOfStory = await chapterOfCurrentStory(
        chaptersFromJson,
        storyData
      );

      let storiesProposed = storiesChapterInsights(
        storiesJson.stories,
        storyData
      );

      let buttonStories = findAdjacentStories(storiesJson.stories, storyData);

      setState({
        ...state,
        storyObj: storyData,
        storyLocation: storyLocationArray,
        storiesJson: storiesJson,
        storiesProposed: storiesProposed,
        previousStory: buttonStories[0].previousStoryId,
        previousStoryUrl:
          i18n.language == "it"
            ? buttonStories[0].previousStoryUrlIt
            : buttonStories[0].previousStoryUrlEn,
        nextStory: buttonStories[1].nextStoryId,
        nextStoryUrl:
          i18n.language == "it"
            ? buttonStories[1].nextStoryUrlIt
            : buttonStories[1].nextStoryUrlEn,
        idStoryFromUrl: storyId,
        chaptersJson: chaptersFromJson,
        currentChapter: chapterOfStory,
        storyUrlIt: currentStoryUrlIt,
        storyUrlEn: currentStoryUrlEn,
        imageLoaded: false,
        isFirstRender: true,
      });

      // Update Open Graph meta tags
      //updateMetaTags(storyData);
    } catch (error) {
      console.error("Error fetching story:", error);
    }
  }

  function setUrl() {
    const fullURL = window.location.href;
    const segments = fullURL.split("/");
    const urlWithoutLangUrl = segments.slice(0, 6).join("/");
    const newPath =
      i18n.language === "it" ? state.storyUrlIt : state.storyUrlEn;
    console.log("New Path:", newPath);
    console.log("URL without Language URL:", urlWithoutLangUrl);
    window.history.pushState({}, "", `${urlWithoutLangUrl}/${newPath}`);
  }

  function scrollToSection() {
    const sectionElement = document.getElementById("body-story");
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
    }
  }

  function renderInsights() {
    console.log("RENDER INSIGHTS SI E' RIPETUTO");
    return (
      <>
        <List
          style={{ color: "white", marginBottom: "20px" }}
          className="left-menu"
        >
          <Link
            to={
              state.currentChapter != null &&
              `/chapters/chapter/${state.currentChapter[0].idDocument}/${
                i18n.language == "it"
                  ? state.currentChapter[0].urlIt
                  : state.currentChapter[0].urlEn
              }`
            }
            className="link-buttons"
          >
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <AutoStoriesIcon style={{ color: "white" }} />
                </ListItemIcon>
                {state.currentChapter != null && (
                  <ListItemText primary={t(state.currentChapter[0]?.code)} />
                )}
              </ListItemButton>
            </ListItem>
          </Link>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <ShieldIcon style={{ color: "white" }} />
              </ListItemIcon>
              {state.storyObj?.category && (
                <ListItemText
                  primary={
                    t("category") + ":   " + t(`${state.storyObj?.category}`)
                  }
                />
              )}
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              href={`https://www.google.com/maps?q=${state.storyObj?.location?.latitude},${state.storyObj?.location?.longitude}`}
              target="_blank"
            >
              <ListItemIcon>
                <LocationOnIcon style={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText primary={t("location")} />
            </ListItemButton>
          </ListItem>
        </List>
        {isDesktopScreen && (
          <Map
            height={"40vh"}
            zoom={14}
            maxZoom={16}
            stories={state.storyLocation}
            withCards={false}
          />
        )}

        {!isRotated && !isDesktopScreen && (
          <Map
            height={"30vh"}
            zoom={14}
            maxZoom={16}
            stories={state.storyLocation}
            withCards={false}
          />
        )}
        {isRotated && !isDesktopScreen && (
          <Map
            height={"80vh"}
            zoom={14}
            maxZoom={16}
            stories={state.storyLocation}
            withCards={false}
          />
        )}
      </>
    );
  }

  function renderButtons() {
    return (
      <>
        <div className="footer-story-buttons">
          <div className="button-previous-story">
            <Button
              variant="outlined"
              disabled={state.previousStory == null}
              sx={{ color: "white" }}
            >
              <Link
                to={
                  state.previousStory != null &&
                  `/stories/story/${state.previousStory}/${state.previousStoryUrl}`
                }
                onClick={() => fetchStory(state.previousStory)}
                className="link-buttons"
              >
                {isDesktopScreen ? (
                  <>
                    <ArrowCircleLeftIcon />‎ ‎‎ ‎ ‎ {t("previousStory")}
                  </>
                ) : (
                  <>
                    <ArrowCircleLeftIcon />‎ ‎‎ ‎ ‎{" "}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {t("previousStoryMobile")}
                    </div>
                  </>
                )}
              </Link>
            </Button>
          </div>
          <div className="button-next-story">
            <Button
              variant="outlined"
              disabled={state.nextStory == null}
              sx={{ color: "white" }}
            >
              <Link
                to={
                  state.nextStory != null &&
                  `/stories/story/${state.nextStory}/${state.nextStoryUrl}`
                }
                onClick={() => fetchStory(state.nextStory)}
                className="link-buttons"
              >
                {isDesktopScreen ? (
                  <>
                    {t("nextStory")}‎ ‎‎ ‎ ‎<ArrowCircleRightIcon />
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {t("nextStoryMobile")}
                    </div>
                    ‎ ‎‎ ‎ ‎<ArrowCircleRightIcon />
                  </>
                )}
              </Link>
            </Button>
          </div>
        </div>
      </>
    );
  }

  function renderSuggestedStories() {
    return (
      <div className="side-stories-container">
        <p
          style={{
            fontSize: "16px",
            color: "white",
            fontWeight: "bold",
            marginBottom: "20px",
          }}
        >
          {t("otherStories")}
        </p>
        {state.storiesProposed?.map((story, key) => (
          <div style={{ marginBottom: "10px", minWidth: "90%" }} key={key}>
            <CustomCard
              key={key}
              content={story}
              maxPicHeight="100px"
              minWidth="25vw"
              withText={false}
              url={`/stories/story/${story.idDocument}/${
                i18n.language == "it" ? story.urlIt : story.urlEn
              }`}
              refetchFunction={() => fetchStory(story.idDocument)}
            />
          </div>
        ))}
      </div>
    );
  }

  function setImageLoaded() {
    console.log(
      "Ora imageLoaded e': ",
      state.imageLoaded,
      "e andrà a : ",
      state.imageLoaded === true ? false : true
    );
    setState({
      ...state,
      imageLoaded: state.imageLoaded === true ? false : true,
    });
  }

  return (
    <div id="start">
      {state.storyObj && (
        <div>
          {state.storyObj?.urlWebImage ? (
            <>
              {!state.imageLoaded && (
                <div
                  className="background-picture"
                  style={{
                    backgroundColor: "#3b4257",
                    zIndex: "1",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Skeleton
                    variant="rectangular"
                    width={"80%"}
                    height={"60%"}
                    style={{ zIndex: "1" }}
                  />
                </div>
              )}

              <img
                src={state.storyObj?.urlWebImage}
                className="background-picture"
                style={{ backgroundColor: "#3b4257" }}
                onLoad={() => setImageLoaded(true)}
                alt="Story background"
              />
            </>
          ) : (
            <Skeleton
              sx={{ bgcolor: "grey.900", height: "100%" }}
              variant="rectangular"
              className="background-picture"
            />
          )}

          <div className="dark-shade"></div>
          <div className="dark-shade-bottom-up"></div>
          <div className="dark-shade-bottom-down"></div>
          <div className="main-container-component">
            {isDesktopScreen && (
              <>
                {state.storyObj?.titleIt && state.storyObj?.titleEn ? (
                  <div
                    className="title shade-title"
                    style={{ zIndex: 1, marginTop: "150px" }}
                  >
                    {i18n.language == "it" && state.storyObj?.titleIt}
                    {i18n.language == "en" && state.storyObj?.titleEn}
                  </div>
                ) : (
                  <Skeleton
                    variant="rounded"
                    height={100}
                    sx={{
                      width: "50%",
                      zIndex: 1,
                      marginTop: "150px",
                    }}
                  />
                )}
                <div
                  className="shade-buttons"
                  style={{ zIndex: 1, width: "100%", marginTop: "50px" }}
                >
                  {renderButtons()}
                </div>
              </>
            )}
            {!isDesktopScreen && (
              <div
                style={{
                  zIndex: 1,
                  height: "90vh",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                {state.storyObj?.titleIt && state.storyObj?.titleEn ? (
                  <div
                    className="title shade-title"
                    style={{
                      zIndex: 1,
                      marginTop: "-30vh",
                      textAlign: "center",
                    }}
                  >
                    {i18n.language == "it" && state.storyObj?.titleIt}
                    {i18n.language == "en" && state.storyObj?.titleEn}
                  </div>
                ) : (
                  <Skeleton
                    variant="rounded"
                    height={100}
                    sx={{
                      width: "50%",
                      zIndex: 1,
                      marginTop: "150px",
                    }}
                  />
                )}
                <button
                  onClick={scrollToSection}
                  className="title shade-title"
                  style={{ fontSize: "15px", marginTop: "-5vh" }}
                >
                  {t("scrollDownToRead")}
                </button>
                <ExpandCircleDownIcon
                  onClick={scrollToSection}
                  style={{
                    color: "white",
                    marginTop: "-5vh",
                    fontSize: "50px",
                  }}
                />
              </div>
            )}
          </div>
          <div
            className={
              isLittleMobileScreen
                ? "story-container-mobile"
                : "story-container"
            }
          >
            <div className="story-left-part" id="body-story">
              <Item elevation={24} style={{ height: "auto" }}>
                {i18n.language == "en" && state.storyObj?.urlMarkdownEn && (
                  <MarkDownReader markdown={state.storyObj?.urlMarkdownEn} />
                )}
                {i18n.language == "it" && state.storyObj?.urlMarkdownIt && (
                  <MarkDownReader markdown={state.storyObj?.urlMarkdownIt} />
                )}
              </Item>
            </div>

            {isDesktopScreen && (
              <div className="story-right-part">
                <div style={{ height: "30vh", paddingLeft: "20px" }}>
                  {renderInsights()}
                  {renderSuggestedStories()}
                </div>
              </div>
            )}
          </div>
          {!isDesktopScreen && (
            <div className="footer-stories-container">
              <Accordion style={{ width: "90vw" }}>
                <AccordionSummary
                  expandIcon={<ExpandCircleDownIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    {t("insights")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{renderInsights()}</Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          )}
          <div style={{ marginBottom: "50px" }}>{renderButtons()}</div>
          {!isDesktopScreen && (
            <div className="footer-stories-container">
              <p
                style={{
                  fontSize: "16px",
                  color: "white",
                  fontWeight: "bold",
                  marginBottom: "20px",
                }}
              >
                {t("otherStories")}
              </p>
              <div
                className="side-stories-container"
                style={{ minWidth: "90%" }}
              >
                {state.storiesProposed?.map((story, key) => (
                  <div
                    key={key}
                    style={{ marginBottom: "10px", minWidth: "90%" }}
                  >
                    <CustomCard
                      key={key}
                      content={story}
                      maxPicHeight="100px"
                      maxWidth="90vw"
                      minWidth="90vw"
                      withText={false}
                      url={`/stories/story/${story.idDocument}/${
                        i18n.language == "it" ? story.urlIt : story.urlEn
                      }`}
                      refetchFunction={() => fetchStory(story.idDocument)}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

// import React, { useEffect, useState } from "react";
// import "../styles/general.css";
// import "../styles/storydetail.css";
// import { useTranslation } from "react-i18next";
// import AutoStoriesIcon from "@mui/icons-material/AutoStories";
// import ShieldIcon from "@mui/icons-material/Shield";
// import LocationOnIcon from "@mui/icons-material/LocationOn";
// import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
// import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
// import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
// import Skeleton from "@mui/material/Skeleton";
// import { Link, useNavigate } from "react-router-dom";
// import {
//   List,
//   ListItem,
//   ListItemButton,
//   ListItemIcon,
//   ListItemText,
//   Paper,
//   styled,
//   useMediaQuery,
//   Button,
//   Accordion,
//   AccordionDetails,
//   AccordionSummary,
//   Typography,
// } from "@mui/material";

// //Components
// import Map from "../components/Map/Map";
// import MarkDownReader from "../components/MarkDownReader/MarkDownReader";
// import CustomCard from "../components/Card/CustomCard";
// import { useParams } from "react-router-dom";
// import {
//   checkChaptersSessionStorage,
//   checkStoriesSessionStorage,
//   getStoryById,
// } from "../utils/firebaseUtils";
// import {
//   chapterOfCurrentStory,
//   findAdjacentStories,
//   google_analytics_measurement_id,
//   storiesChapterInsights,
// } from "../utils/dataUtils";
// import useScreenRotation, { scrollToStartSection } from "../utils/uiUtils";
// import ReactGA from "react-ga4";

// const Item = styled(Paper)(({ theme }) => ({
//   ...theme.typography.body2,
//   textAlign: "center",
//   color: theme.palette.text.secondary,
//   height: 60,
//   lineHeight: "60px",
// }));

// export default function StoryDetail(props) {
//   const { t, i18n } = useTranslation();
//   const { id } = useParams();
//   const isDesktopScreen = useMediaQuery("(min-width:1025px)");
//   const isLittleMobileScreen = useMediaQuery("(max-width:500px)");
//   const isRotated = useScreenRotation();
//   const navigate = useNavigate();

//   const [state, setState] = useState({
//     storyObj: {},
//     storyLocation: [],
//     idStoryFromUrl: "",
//     imageLoaded: false,
//     isFirstRender: false,
//   });
//   ReactGA.initialize(google_analytics_measurement_id);

//   function setImageLoaded() {
//     console.log(
//       "Ora imageLoaded e': ",
//       state.imageLoaded,
//       "e andrà a : ",
//       state.imageLoaded === true ? false : true
//     );
//     setState({
//       ...state,
//       imageLoaded: state.imageLoaded === true ? false : true,
//     });
//   }

//   function scrollToSection() {
//     const sectionElement = document.getElementById("body-story");

//     if (sectionElement) {
//       sectionElement.scrollIntoView({ behavior: "smooth" });
//     }
//   }

//   function renderInsights() {
//     console.log("RENDER INSIGHTS SI E' RIPETUTO");
//     return (
//       <>
//         <List
//           style={{ color: "white", marginBottom: "20px" }}
//           className="left-menu"
//         >
//           <Link
//             to={
//               state.currentChapter != null &&
//               `/chapters/chapter/${state.currentChapter[0].idDocument}/${
//                 i18n.language == "it"
//                   ? state.currentChapter[0].urlIt
//                   : state.currentChapter[0].urlEn
//               }`
//             }
//             className="link-buttons"
//           >
//             <ListItem disablePadding>
//               <ListItemButton>
//                 <ListItemIcon>
//                   <AutoStoriesIcon style={{ color: "white" }} />
//                 </ListItemIcon>
//                 {state.currentChapter != null && (
//                   <ListItemText primary={t(state.currentChapter[0]?.code)} />
//                 )}
//               </ListItemButton>
//             </ListItem>
//           </Link>
//           <ListItem disablePadding>
//             <ListItemButton>
//               <ListItemIcon>
//                 <ShieldIcon style={{ color: "white" }} />
//               </ListItemIcon>
//               {state.storyObj?.category && (
//                 <ListItemText
//                   primary={
//                     t("category") + ":   " + t(`${state.storyObj?.category}`)
//                   }
//                 />
//               )}
//             </ListItemButton>
//           </ListItem>
//           <ListItem disablePadding>
//             <ListItemButton
//               href={`https://www.google.com/maps?q=${state.storyObj?.location?.latitude},${state.storyObj?.location?.longitude}`}
//               target="_blank"
//             >
//               <ListItemIcon>
//                 <LocationOnIcon style={{ color: "white" }} />
//               </ListItemIcon>
//               <ListItemText primary={t("location")} />
//             </ListItemButton>
//           </ListItem>
//         </List>
//         {isDesktopScreen && (
//           <Map
//             height={"40vh"}
//             zoom={14}
//             maxZoom={16}
//             stories={state.storyLocation}
//             withCards={false}
//           />
//         )}

//         {!isRotated && !isDesktopScreen && (
//           <Map
//             height={"30vh"}
//             zoom={14}
//             maxZoom={16}
//             stories={state.storyLocation}
//             withCards={false}
//           />
//         )}
//         {isRotated && !isDesktopScreen && (
//           <Map
//             height={"80vh"}
//             zoom={14}
//             maxZoom={16}
//             stories={state.storyLocation}
//             withCards={false}
//           />
//         )}
//       </>
//     );
//   }

//   function renderButtons() {
//     return (
//       <>
//         <div className="footer-story-buttons">
//           <div className="button-previous-story">
//             <Button
//               variant="outlined"
//               disabled={state.previousStory == null}
//               sx={{ color: "white" }}
//             >
//               <Link
//                 to={
//                   state.previousStory != null &&
//                   `/stories/story/${state.previousStory}/${state.previousStoryUrl}`
//                 }
//                 onClick={() => fetchStory(state.previousStory)}
//                 className="link-buttons"
//               >
//                 {isDesktopScreen ? (
//                   <>
//                     <ArrowCircleLeftIcon />‎ ‎‎ ‎ ‎ {t("previousStory")}
//                   </>
//                 ) : (
//                   <>
//                     <ArrowCircleLeftIcon />‎ ‎‎ ‎ ‎{" "}
//                     <div
//                       style={{
//                         display: "flex",
//                         flexDirection: "column",
//                         alignItems: "center",
//                       }}
//                     >
//                       {t("previousStoryMobile")}
//                     </div>
//                   </>
//                 )}
//               </Link>
//             </Button>
//           </div>
//           <div className="button-next-story">
//             <Button
//               variant="outlined"
//               disabled={state.nextStory == null}
//               sx={{ color: "white" }}
//             >
//               <Link
//                 to={
//                   state.nextStory != null &&
//                   `/stories/story/${state.nextStory}/${state.nextStoryUrl}`
//                 }
//                 onClick={() => fetchStory(state.nextStory)}
//                 className="link-buttons"
//               >
//                 {isDesktopScreen ? (
//                   <>
//                     {t("nextStory")}‎ ‎‎ ‎ ‎<ArrowCircleRightIcon />
//                   </>
//                 ) : (
//                   <>
//                     <div
//                       style={{
//                         display: "flex",
//                         flexDirection: "column",
//                         alignItems: "center",
//                       }}
//                     >
//                       {t("nextStoryMobile")}
//                     </div>
//                     ‎ ‎‎ ‎ ‎<ArrowCircleRightIcon />
//                   </>
//                 )}
//               </Link>
//             </Button>
//           </div>
//         </div>
//       </>
//     );
//   }

//   function renderSuggestedStories() {
//     return (
//       <div className="side-stories-container">
//         <p
//           style={{
//             fontSize: "16px",
//             color: "white",
//             fontWeight: "bold",
//             marginBottom: "20px",
//           }}
//         >
//           {t("otherStories")}
//         </p>
//         {state.storiesProposed?.map((story, key) => (
//           <div style={{ marginBottom: "10px", minWidth: "90%" }} key={key}>
//             <CustomCard
//               key={key}
//               content={story}
//               maxPicHeight="100px"
//               minWidth="25vw"
//               withText={false}
//               url={`/stories/story/${story.idDocument}/${
//                 i18n.language == "it" ? story.urlIt : story.urlEn
//               }`}
//               refetchFunction={() => fetchStory(story.idDocument)}
//             />
//           </div>
//         ))}
//       </div>
//     );
//   }
//   async function fetchStory(idDocument) {
//     scrollToStartSection();
//     let storyId = idDocument != null ? idDocument : id.split("/").pop();
//     try {
//       let storyData = await getStoryById(storyId);
//       console.log("(VIEW) Story data fetched: ", storyData);

//       ReactGA.send({
//         hitType: "pageview",
//         page: "/stories/story",
//         title: `Story view: ${storyData.titleIt}`,
//       });

//       let storyLocationArray = [
//         {
//           latitude: storyData?.location?.latitude,
//           longitude: storyData?.location?.longitude,
//         },
//       ];

//       let currentStoryUrlIt = storyData.urlIt;
//       let currentStoryUrlEn = storyData.urlEn;

//       let storiesJson = await checkStoriesSessionStorage();
//       let chaptersFromJson = await checkChaptersSessionStorage();

//       let chapterOfStory = await chapterOfCurrentStory(
//         chaptersFromJson,
//         storyData
//       );

//       let storiesProposed = storiesChapterInsights(
//         storiesJson.stories,
//         storyData
//       );

//       let buttonStories = findAdjacentStories(storiesJson.stories, storyData);
//       setState({
//         ...state,
//         storyObj: storyData,
//         storyLocation: storyLocationArray,
//         storiesJson: storiesJson,
//         storiesProposed: storiesProposed,
//         previousStory: buttonStories[0].previousStoryId,
//         previousStoryUrl:
//           i18n.language == "it"
//             ? buttonStories[0].previousStoryUrlIt
//             : buttonStories[0].previousStoryUrlEn,
//         nextStory: buttonStories[1].nextStoryId,
//         nextStoryUrl:
//           i18n.language == "it"
//             ? buttonStories[1].nextStoryUrlIt
//             : buttonStories[1].nextStoryUrlEn,
//         idStoryFromUrl: storyId,
//         chaptersJson: chaptersFromJson,
//         currentChapter: chapterOfStory,
//         storyUrlIt: currentStoryUrlIt,
//         storyUrlEn: currentStoryUrlEn,
//         imageLoaded: false,
//         isFirstRender: true,
//       });
//     } catch (error) {
//       console.error("Error fetching story:", error);
//     }
//   }

//   function setUrl() {
//     const fullURL = window.location.href;
//     const segments = fullURL.split("/");
//     const urlWithoutLangUrl = segments.slice(0, 6).join("/");
//     const newPath =
//       i18n.language === "it" ? state.storyUrlIt : state.storyUrlEn;
//     console.log("New Path:", newPath);
//     console.log("URL without Language URL:", urlWithoutLangUrl);
//     window.history.pushState({}, "", `${urlWithoutLangUrl}/${newPath}`);
//   }

//   useEffect(() => {
//     fetchStory();
//     console.log("18n.language", i18n.language);
//   }, []);

//   useEffect(() => {
//     if (state.isFirstRender) {
//       setUrl();
//     }
//   }, [i18n.language]);
//   return (
//     <div id="start">
//       {state.storyObj && (
//         <div>
//           {state.storyObj?.urlWebImage ? (
//             <>
//               {!state.imageLoaded && (
//                 <div
//                   className="background-picture"
//                   style={{
//                     backgroundColor: "#3b4257",
//                     zIndex: "1",
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                   }}
//                 >
//                   <Skeleton
//                     variant="rectangular"
//                     width={"80%"}
//                     height={"60%"}
//                     style={{ zIndex: "1" }}
//                   />
//                 </div>
//               )}

//               <img
//                 src={state.storyObj?.urlWebImage}
//                 className="background-picture"
//                 style={{ backgroundColor: "#3b4257" }}
//                 onLoad={setImageLoaded}
//               ></img>
//             </>
//           ) : (
//             <Skeleton
//               sx={{ bgcolor: "grey.900", height: "100%" }}
//               variant="rectangular"
//               className="background-picture"
//             />
//           )}

//           <div className="dark-shade"></div>
//           <div className="dark-shade-bottom-up"></div>
//           <div className="dark-shade-bottom-down"></div>
//           <div className="main-container-component">
//             {isDesktopScreen && (
//               <>
//                 {state.storyObj?.titleIt && state.storyObj?.titleEn ? (
//                   <div
//                     className="title shade-title"
//                     style={{ zIndex: 1, marginTop: "150px" }}
//                   >
//                     {i18n.language == "it" && state.storyObj?.titleIt}
//                     {i18n.language == "en" && state.storyObj?.titleEn}
//                   </div>
//                 ) : (
//                   <Skeleton
//                     variant="rounded"
//                     height={100}
//                     sx={{
//                       width: "50%",
//                       zIndex: 1,
//                       marginTop: "150px",
//                     }}
//                   />
//                 )}
//                 <div
//                   className="shade-buttons"
//                   style={{ zIndex: 1, width: "100%", marginTop: "50px" }}
//                 >
//                   {renderButtons()}
//                 </div>
//               </>
//             )}
//             {!isDesktopScreen && (
//               <div
//                 style={{
//                   zIndex: 1,
//                   height: "90vh",
//                   display: "flex",
//                   flexDirection: "column",
//                   alignItems: "center",
//                   alignContent: "center",
//                   justifyContent: "center",
//                 }}
//               >
//                 {state.storyObj?.titleIt && state.storyObj?.titleEn ? (
//                   <div
//                     className="title shade-title"
//                     style={{
//                       zIndex: 1,
//                       marginTop: "-30vh",
//                       textAlign: "center",
//                     }}
//                   >
//                     {i18n.language == "it" && state.storyObj?.titleIt}
//                     {i18n.language == "en" && state.storyObj?.titleEn}
//                   </div>
//                 ) : (
//                   <Skeleton
//                     variant="rounded"
//                     height={100}
//                     sx={{
//                       width: "50%",
//                       zIndex: 1,
//                       marginTop: "150px",
//                     }}
//                   />
//                 )}
//                 <button
//                   onClick={scrollToSection}
//                   className="title shade-title"
//                   style={{ fontSize: "15px", marginTop: "-5vh" }}
//                 >
//                   {t("scrollDownToRead")}
//                 </button>
//                 <ExpandCircleDownIcon
//                   onClick={scrollToSection}
//                   style={{
//                     color: "white",
//                     marginTop: "-5vh",
//                     fontSize: "50px",
//                   }}
//                 />
//               </div>
//             )}
//           </div>
//           <div
//             className={
//               isLittleMobileScreen
//                 ? "story-container-mobile"
//                 : "story-container"
//             }
//           >
//             <div className="story-left-part" id="body-story">
//               <Item elevation={24} style={{ height: "auto" }}>
//                 {i18n.language == "en" && state.storyObj?.urlMarkdownEn && (
//                   <MarkDownReader markdown={state.storyObj?.urlMarkdownEn} />
//                 )}
//                 {i18n.language == "it" && state.storyObj?.urlMarkdownIt && (
//                   <MarkDownReader markdown={state.storyObj?.urlMarkdownIt} />
//                 )}
//               </Item>
//             </div>

//             {isDesktopScreen && (
//               <div className="story-right-part">
//                 <div style={{ height: "30vh", paddingLeft: "20px" }}>
//                   {renderInsights()}
//                   {renderSuggestedStories()}
//                 </div>
//               </div>
//             )}
//           </div>
//           {!isDesktopScreen && (
//             <div className="footer-stories-container">
//               {" "}
//               <Accordion style={{ width: "90vw" }}>
//                 <AccordionSummary
//                   expandIcon={<ExpandCircleDownIcon />}
//                   aria-controls="panel2-content"
//                   id="panel2-header"
//                 >
//                   <Typography sx={{ fontWeight: "bold" }}>
//                     {t("insights")}
//                   </Typography>
//                 </AccordionSummary>
//                 <AccordionDetails>
//                   <Typography>{renderInsights()}</Typography>
//                 </AccordionDetails>
//               </Accordion>
//             </div>
//           )}
//           <div style={{ marginBottom: "50px" }}>{renderButtons()}</div>
//           {!isDesktopScreen && (
//             <div className="footer-stories-container">
//               <p
//                 style={{
//                   fontSize: "16px",
//                   color: "white",
//                   fontWeight: "bold",
//                   marginBottom: "20px",
//                 }}
//               >
//                 {t("otherStories")}
//               </p>
//               <div
//                 className="side-stories-container"
//                 style={{ minWidth: "90%" }}
//               >
//                 {state.storiesProposed?.map((story, key) => (
//                   <div
//                     key={key}
//                     style={{ marginBottom: "10px", minWidth: "90%" }}
//                   >
//                     <CustomCard
//                       key={key}
//                       content={story}
//                       maxPicHeight="100px"
//                       maxWidth="90vw"
//                       minWidth="90vw"
//                       withText={false}
//                       url={`/stories/story/${story.idDocument}/${
//                         i18n.language == "it" ? story.urlIt : story.urlEn
//                       }`}
//                       refetchFunction={() => fetchStory(story.idDocument)}
//                     />
//                   </div>
//                 ))}
//               </div>
//             </div>
//           )}
//         </div>
//       )}
//     </div>
//   );
// }
