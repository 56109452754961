// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBDSol7ZJatLSjh_y0N3XH9O6TispjiY3g",
  authDomain: "lorelandwebsite.firebaseapp.com",
  projectId: "lorelandwebsite",
  storageBucket: "lorelandwebsite.appspot.com",
  messagingSenderId: "67091556730",
  appId: "1:67091556730:web:12651ab2411818ce6f8ec3",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const db = getFirestore(app);

// Initialize Cloud Functions and export the needed callable functions
export const functions = getFunctions(app);

// Example of exporting a specific callable functio

// Optionally, if you have other callable functions, you can export them similarly
// export const createStripeCheckout = httpsCallable(functions, 'createStripeCheckout');

// If you decide to use Firebase Storage, uncomment the following lines
// import { getStorage } from "firebase/storage";
// export const firestorage = getStorage(app);

// Optionally, export the app itself if needed elsewhere
export default app;

// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAuth } from "firebase/auth";
// import { getFirestore } from "firebase/firestore";
// // import { getStorage } from "firebase/storage";
// // // import firebase from "firebase/compat/app";
// // // import "firebase/firestore";
// // // import "firebase/functions";
// // import { getFunctions, httpsCallable } from "firebase/functions";

// // const fc = firebase.functions();

// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyBDSol7ZJatLSjh_y0N3XH9O6TispjiY3g",
//   authDomain: "lorelandwebsite.firebaseapp.com",
//   projectId: "lorelandwebsite",
//   storageBucket: "lorelandwebsite.appspot.com",
//   messagingSenderId: "67091556730",
//   appId: "1:67091556730:web:12651ab2411818ce6f8ec3",
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// // Initialize Firebase Authentication and get a reference to the service
// export const auth = getAuth(app);
// export const db = getFirestore(app);
// // export const functions = getFunctions(app);
// // export const firestorage = getStorage(app);
// // export const createStripeCheckout = httpsCallable(
// //   functions,
// //   "createStripeCheckout"
// // );
// // export default app;
