import React, { useEffect, useState } from "react";
import "../styles/cookiespolicy.css";

export default function CookiesPolicy(props) {
  const [state, setState] = useState({
    ordersList: null,
    mostRecentOrders: [],
  });
  useEffect(() => {}, []);

  return (
    <>
      <div className="flex justify-around ">
        <div
          className="text-center flex flex-column flex-center"
          style={{ gap: 20 }}
        >
          <h2>Cookies Policy</h2>
        </div>
      </div>
    </>
  );
}
