import React, { useEffect, useState } from "react";
import "../styles/general.css";
import "../styles/stories.css";
import { useTranslation } from "react-i18next";
import CustomPagination from "../components/Pagination/CustomPagination";
import {
  categoriesUtils,
  chaptersUtils,
  google_analytics_measurement_id,
} from "../utils/dataUtils";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import FilterListIcon from "@mui/icons-material/FilterList";

//Components
import CustomCard from "../components/Card/CustomCard";
import { checkStoriesSessionStorage } from "../utils/firebaseUtils";
import SkeletonCardsGrid from "../components/Card/SkeletonCardsGrid";
import FilterButton from "../components/FilterButton/FilterButton";
import {
  Box,
  IconButton,
  Modal,
  TextField,
  useMediaQuery,
} from "@mui/material";
import Map from "../components/Map/Map";
import useScreenRotation, { scrollToStartSection } from "../utils/uiUtils";
import ReactGA from "react-ga4";

export default function Stories(props) {
  const { t, i18n } = useTranslation();
  const isDesktopScreen = useMediaQuery("(min-width:1025px)");
  const isReallyMobileScreen = useMediaQuery("(max-width:400px)");
  const isRotated = useScreenRotation();
  const [state, setState] = useState({
    ordersList: null,
    stories: null,
    page: 0,
    rowsPerPage: 10,
    responseStories: null,
    jsonStories: [],
    jsonStoriesFixed: [],
    filterCategory: "",
    filterChapter: "",
    filterTitle: "",
    isFilterTitleOn: false,
    startPagination: 0,
    endPagination: 12,
    currentPage: "",
    imposedPage: 0,
  });
  ReactGA.initialize(google_analytics_measurement_id);
  ReactGA.send({
    hitType: "pageview",
    page: "/stories",
    title: "Stories view",
  });

  async function fetchJsonStories() {
    scrollToStartSection();
    const dataFromJson = await checkStoriesSessionStorage();
    console.log("DATA FROM JSON :", dataFromJson);
    setState({
      ...state,
      jsonStories: dataFromJson,
      jsonStoriesFixed: dataFromJson,
    });
  }

  useEffect(() => {
    fetchJsonStories();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [state.filterCategory, state.filterChapter]); // Reapply filters whenever category, chapter, or title filters change

  function setPage(page) {
    console.log("Da positzione: ", page * 12 - 12, " a positione: ", page * 12);
    setState({
      ...state,
      startPagination: page * 12 - 12,
      endPagination: page * 12,
      currentPage: page,
    });
  }

  function resetFilterChapter() {
    setState({
      ...state,
      filterChapter: "",
      startPagination: state.currentPage * 12 - 12,
      endPagination: state.currentPage * 12,
      currentPage: 1,
    });
  }

  function resetFilterCategory() {
    setState({
      ...state,
      filterCategory: "",
      startPagination: state.currentPage * 12 - 12,
      endPagination: state.currentPage * 12,
      currentPage: 1,
    });
  }

  function resetFilterTitle() {
    let filteredStories = state.jsonStoriesFixed.stories;
    if (state.filterChapter !== "") {
      filteredStories = filteredStories.filter(
        (story) => story.chapter === state.filterChapter
      );
    }
    if (state.filterCategory !== "") {
      filteredStories = filteredStories.filter(
        (story) => story.category === state.filterCategory
      );
    }
    setState({
      ...state,
      jsonStories: { ...state.jsonStories, stories: filteredStories },
      filterTitle: "",
      startPagination: state.currentPage * 12 - 12,
      endPagination: state.currentPage * 12,
      isFilterTitleOn: false,
      currentPage: 1,
    });
  }

  function filterByChapter(option) {
    setState({
      ...state,
      filterChapter: option,
      startPagination: 0,
      endPagination: 12,
      currentPage: 1,
    });
  }

  function filterByCategory(option) {
    setState({
      ...state,
      filterCategory: option,
      startPagination: 0,
      endPagination: 12,
      currentPage: 1,
    });
  }

  function handleSearch() {
    applyFilters();
  }

  function handleTitleChange(event) {
    setState({
      ...state,
      filterTitle: event.target.value,
    });
  }

  function applyFilters() {
    let filteredStories = state.jsonStoriesFixed.stories;
    let filterTitleOn = false;
    if (state.filterChapter !== "") {
      filteredStories = filteredStories.filter(
        (story) => story.chapter === state.filterChapter
      );
    }

    if (state.filterCategory !== "") {
      filteredStories = filteredStories.filter(
        (story) => story.category === state.filterCategory
      );
    }

    if (state.filterTitle !== "") {
      const normalizedTitle = state.filterTitle.toLowerCase(); // Normalize the title
      console.log(
        "state.filterTitle.toLowerCase()",
        state.filterTitle.toLowerCase()
      );

      filteredStories = filteredStories.filter((item) => {
        return i18n.language == "it"
          ? item.titleIt.toLowerCase().includes(normalizedTitle)
          : item.titleEn.toLowerCase().includes(normalizedTitle);
      });

      filterTitleOn = true;
    }

    setState({
      ...state,
      jsonStories: {
        ...state.jsonStories,
        stories: filteredStories,
      },
      startPagination: 0,
      endPagination: 12,
      isFilterTitleOn: filterTitleOn,
      currentPage: 1,
    });
  }

  function renderFiltersBar() {
    return (
      <div className="filters-bar">
        <div className="text-stories">{t("filterBy")}</div>
        <div>
          <FilterButton
            onMenuClick={filterByCategory}
            resetFunction={resetFilterCategory}
            dataOfMenu={categoriesUtils}
            filterName={"category"}
            colorChosen={"white"}
            filterChosen={state.filterCategory}
            filterFromExternalState={state.filterCategory}
          />
        </div>
        <div style={{ marginLeft: "10px" }}>
          <FilterButton
            onMenuClick={filterByChapter}
            resetFunction={resetFilterChapter}
            dataOfMenu={chaptersUtils}
            filterName={"chapter"}
            colorChosen={"white"}
            filterChosen={state.filterChapter}
            filterFromExternalState={state.filterChapter}
          />
        </div>
        <div className="text-stories" style={{ marginLeft: "40px" }}>
          {t("searchBy")}
        </div>
        <div
          style={{
            color: "white",
            display: "flex",
            alignItems: "center",
          }}
        >
          <TextField
            d="outlined-basic"
            label={t("title")}
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#2196f3",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
              },
              input: { color: "white" },
              borderColor: "white",
            }}
            InputLabelProps={{ style: { color: "white" } }}
            onChange={handleTitleChange}
            value={state.filterTitle}
            // Add endAdornment for the cancel icon
            InputProps={{
              endAdornment: state.filterTitle && (
                <IconButton
                  aria-label="clear search"
                  onClick={resetFilterTitle}
                  edge="end"
                  style={{ color: "white" }} // Adjust color
                >
                  <CancelIcon />
                </IconButton>
              ),
            }}
          />
          <div style={{ marginLeft: "10px" }}>
            <IconButton
              aria-label="fingerprint"
              sx={{
                backgroundColor: "#2196f3",
                color: "white",
                "&:hover": {
                  backgroundColor: "white",
                  color: "black",
                },
              }}
              onClick={handleSearch}
            >
              <SearchIcon />
            </IconButton>
          </div>
        </div>
      </div>
    );
  }

  function handleFilterModalOpen() {
    setState({
      ...state,
      isFilterModalOpen: true,
    });
  }

  function handleFilterModalClose() {
    setState({
      ...state,
      isFilterModalOpen: false,
    });
  }

  function renderFiltersModal() {
    return (
      <Modal
        open={state.isFilterModalOpen}
        onClose={handleFilterModalClose}
        aria-labelledby="filter-modal-title"
        aria-describedby="filter-modal-description"
      >
        <Box
          sx={{
            justifyContent: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleFilterModalClose}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              color: "action",
            }}
          >
            <CancelIcon />
          </IconButton>
          <div className="filters-modal">
            <div
              style={{
                fontSize: 16,
                color: "black",
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              {t("filterBy")}
            </div>
            <div style={{ marginBottom: "10px" }}>
              <FilterButton
                onMenuClick={filterByCategory}
                resetFunction={resetFilterCategory}
                dataOfMenu={categoriesUtils}
                filterName={"category"}
                colorChosen={"black"}
                filterChosen={state.filterCategory}
                filterFromExternalState={state.filterCategory}
              />
            </div>
            <div style={{ marginBottom: "20px" }}>
              <FilterButton
                onMenuClick={filterByChapter}
                resetFunction={resetFilterChapter}
                dataOfMenu={chaptersUtils}
                filterName={"chapter"}
                colorChosen={"black"}
                filterChosen={state.filterChapter}
                filterFromExternalState={state.filterChapter}
              />
            </div>
            <div
              style={{
                fontSize: 16,
                color: "black",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              {t("searchBy")}
            </div>
            <div
              style={{
                color: "black",
                display: "flex",
                alignItems: "center",
              }}
            >
              <TextField
                d="outlined-basic"
                label={t("title")}
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#2196f3",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "black",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "black",
                    },
                  },
                  input: { color: "black" },
                  borderColor: "white",
                }}
                InputLabelProps={{ style: { color: "black" } }}
                onChange={handleTitleChange}
                value={state.filterTitle}
                // Add endAdornment for the cancel icon
                InputProps={{
                  endAdornment: state.filterTitle && (
                    <IconButton
                      aria-label="clear search"
                      onClick={resetFilterTitle}
                      edge="end"
                      style={{ color: "black" }} // Adjust color
                    >
                      <CancelIcon />
                    </IconButton>
                  ),
                }}
              />
              <div style={{ marginLeft: "10px" }}>
                <IconButton
                  aria-label="fingerprint"
                  sx={{
                    backgroundColor: "#2196f3",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "black",
                    },
                  }}
                  onClick={handleSearch}
                >
                  <SearchIcon />
                </IconButton>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    );
  }

  function renderMobileFilterButton() {
    return (
      <div
        style={{
          display: "flex",
          width: "85%",
          justifyContent: "start",
        }}
      >
        <IconButton
          aria-label="fingerprint"
          sx={{
            backgroundColor: "#2196f3",
            color: "white",
            "&:hover": {
              backgroundColor: "white",
              color: "black",
            },
          }}
          onClick={handleFilterModalOpen}
        >
          <FilterListIcon />
        </IconButton>
      </div>
    );
  }

  function mapStories() {
    return (
      <div className="card-container">
        {state.jsonStories?.stories
          ?.slice(state.startPagination, state.endPagination)
          .map((story, key) => (
            <CustomCard
              content={story}
              withText={true}
              maxWidth={isReallyMobileScreen ? "100%" : "400px"}
              url={`/stories/story/${story.idDocument}/${
                i18n.language == "it" ? story.urlIt : story.urlEn
              }`}
            />
          ))}
        <div style={{ width: "100%", justifyContent: "center" }}>
          {!state.jsonStories?.stories && (
            <div
              style={{
                width: "100%",
                justifyContent: "center",
              }}
            >
              <SkeletonCardsGrid direction={"row"} />{" "}
              <SkeletonCardsGrid direction={"row"} />
            </div>
          )}
          {state.jsonStories?.stories?.slice(
            state.startPagination,
            state.endPagination
          ).length <= 0 && (
            <p className="no-story-found">{t("noStoryFound")}</p>
          )}
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="main-container-component">
        <div className="title">{t("storiesTitle")}</div>
        {isDesktopScreen && renderFiltersBar()}
        {!isDesktopScreen && renderFiltersModal()}
        {!isDesktopScreen && renderMobileFilterButton()}
        <div className="stories-grid">{mapStories()}</div>
        <CustomPagination
          setPagination={setPage}
          storiesLength={state.jsonStories?.stories?.length}
          pageImposed={state.currentPage}
        />
        <div className="map-stories-title" style={{ marginBottom: "30px" }}>
          {t("mapOfStories")}
        </div>
        {isDesktopScreen && renderFiltersBar()}
        {isDesktopScreen && (
          <div
            style={{
              width: "90%",
              height: "100%",
              marginBottom: "50px",
              marginTop: "20px",
            }}
          >
            <Map
              zoom={10}
              maxZoom={16}
              stories={state.jsonStories?.stories}
              height={"70vh"}
              withCards={true}
            />
          </div>
        )}
        {!isDesktopScreen && renderFiltersModal()}
        {!isDesktopScreen && renderMobileFilterButton()}
        {!isDesktopScreen && !isRotated && (
          <div
            style={{
              width: "90%",
              height: "100%",
              marginBottom: "50px",
              marginTop: "20px",
            }}
          >
            <Map
              zoom={10}
              maxZoom={16}
              stories={state.jsonStories?.stories}
              height={"50vh"}
              withCards={true}
            />
          </div>
        )}
        {!isDesktopScreen && isRotated && (
          <div
            style={{
              width: "90%",
              height: "100%",
              marginBottom: "50px",
              marginTop: "20px",
            }}
          >
            <Map
              zoom={10}
              maxZoom={16}
              stories={state.jsonStories?.stories}
              height={"80vh"}
              withCards={true}
            />
          </div>
        )}
      </div>
    </div>
  );
}
