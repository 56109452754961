import React from "react";
import "./animatedlogo.css";
import PropTypes from "prop-types";
import Logo from "../../assets/images/icon.png";

export default function AnimatedLogo({ customClass }) {
  return (
    <div className={`image-wrapper shine ${customClass}`}>
      <img src={Logo} alt="logo" className="logo-image" />
    </div>
  );
}

AnimatedLogo.propTypes = {
  customClass: PropTypes.arrayOf(PropTypes.string),
};
