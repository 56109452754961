import React, { useEffect, useState } from "react";
import "../styles/pagenotfound.css";
import "../styles/general.css";
import ImgNotFound from "../assets/images/notFound.jpg";
import { Button, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import { google_analytics_measurement_id } from "../utils/dataUtils";

export default function PageNotFound(props) {
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const { t, i18n } = useTranslation();
  const [state, setState] = useState({
    ordersList: null,
    mostRecentOrders: [],
  });
  useEffect(() => {}, []);
  ReactGA.initialize(google_analytics_measurement_id);
  ReactGA.send({
    hitType: "pageview",
    page: "/pagenotfound",
    title: "Page not found view",
  });
  return (
    <>
      <div className="flex justify-around ">
        <div className="not-found-container" style={{ gap: 20 }}>
          <div className="title" style={{ marginBottom: "-15px" }}>
            {t("pageNotFound")}
          </div>
          <Button variant="outlined" sx={{ color: "white" }}>
            <Link to={"/"} className="link-buttons">
              {t("goBackHome")}
            </Link>
          </Button>
          <img
            className={
              isMobileScreen ? "not-found-picture-mobile" : "not-found-picture"
            }
            style={{ marginBottom: "40px" }}
            src={ImgNotFound}
          />
        </div>
      </div>
    </>
  );
}
