import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import Routing from "./routing/routing";
import { AuthContextProvider } from "./context/AuthContext";
import { ParallaxProvider } from "react-scroll-parallax";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/translations/i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <AuthContextProvider>
        <ParallaxProvider>
          {/* <App /> */}
          <Routing />
          <ToastContainer hideProgressBar />
        </ParallaxProvider>
      </AuthContextProvider>
    </BrowserRouter>
  </Provider>
);
