// AuthContext.js
import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { auth, firestore } from "../firebase";
import { setRole } from "../redux/duck/role/roleDuck";

import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import { useDispatch } from "react-redux";

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [userRole, setUserRole] = useState(null);
  const dispatch = useDispatch();

  // find the role of the user
  const getUserRole = async (uid) => {
    const rolesCollectionRef = collection(db, "roles");

    try {
      // Create a query to search for the document with a matching UID
      const q = query(rolesCollectionRef, where("id", "==", uid));

      // Get the documents that match the query
      const querySnapshot = await getDocs(q);

      // Initialize a variable to store the user role
      let userRole = "user"; // Default role if not found
      console.log("Snapshot :", querySnapshot);
      // Loop through the documents (there should be at most one)
      querySnapshot.forEach((doc) => {
        // Access the role data if the document exists
        const userData = doc.data();
        console.log("Inside the getUserRole for each: ", userData);
        userRole = userData.role || "user"; // Use the role from the document, or default to "user"
      });

      return userRole;
    } catch (error) {
      console.error("Error getting user role:", error);
      return "user"; // Default role if there's an error
    }
  };

  const createUser = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  // function roleEvent(role) {
  //   let roleEvent = new CustomEvent("setRoleEvent", { detail: role });
  //   // document.dispatchEvent(roleEvent);
  // }

  const signIn = async (email, password) => {
    try {
      const authUser = await signInWithEmailAndPassword(auth, email, password);
      // console.log("authUser: ", authUser);
      const uid = authUser.user.uid;
      console.log("authUser.user.uid: ", authUser.user.uid);
      let userRole = await getUserRole(uid);
      console.log("Role of the user is: ", userRole);

      dispatch(setRole(userRole));
      // roleEvent(userRole);

      setUser(authUser.user);
    } catch (e) {
      // Handle error
    }
  };

  const logout = () => {
    return signOut(auth);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserContext.Provider
      value={{ createUser, user, userRole, logout, signIn }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  // console.log("UserContext :", useContext(UserContext));
  return useContext(UserContext);
};
