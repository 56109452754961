// useScreenRotation.js
import { useEffect, useState } from "react";

// function that returns if the screen of the mobile devices is rotated or not
function useScreenRotation() {
  const [isRotated, setIsRotated] = useState(false);

  function handleResize() {
    const isLandscape = window.innerWidth > window.innerHeight;
    setIsRotated(isLandscape);
  }

  useEffect(() => {
    // Add resize event listener
    window.addEventListener("resize", handleResize);
    // Initial check
    handleResize();

    // Remove event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isRotated;
}

export function scrollToStartSection() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

export default useScreenRotation;
