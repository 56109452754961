export const urlStoriesJson = "7GaNrl34utwq7C9lvHz4";
export const urlChaptersJson = "IByG0gNkyyWsa6bNs4we";
export const urlPostsJson = "qlC5XYmXVXlqwct3f9Hp";

export const google_analytics_measurement_id = "G-WB81SS6GQP";

export const defaultLocation = [
  {
    geocode: [46.004206, 9.262435],
    popUp: "Hello, I am pop up 3",
  },
];

export const chaptersUtils = [
  "Depths of Lario", //Depths of Lario
  /*"Mists of Cavargna",*/
];

export const categoriesUtils = [
  "demons",
  "witches",
  "ghosts",
  "creatures",
  "miracles",
  "history",
  "others",
];

export const categoriesPostsUtils = [
  "paths",
  "events",
  "curiosities",
  "gastronomy",
  "places",
  "others",
];

export const videoUrlHomeIt =
  "https://firebasestorage.googleapis.com/v0/b/lorelandwebsite.appspot.com/o/videos%2FLands%20of%20tales%20-%20the%20depths%20of%20lario.mp4?alt=media&token=0457a932-ee96-41dc-81cd-a4b01ec38e7c";
export const videoUrlHomeEn =
  "https://firebasestorage.googleapis.com/v0/b/lorelandwebsite.appspot.com/o/videos%2FLands%20of%20tales%20-%20the%20depths%20of%20lario%20EN.mp4?alt=media&token=9a280503-d4f7-4d79-80bc-cdb9686a9078";

export function storiesChapterInsights(storiesJson, currentStory) {
  let chapter = currentStory.chapter;
  const filteredStories = storiesJson.filter(
    (story) => story.chapter === chapter
  );
  const filteredStoriesExcludingCurrent = filteredStories.filter(
    (story) => story.idDocument !== currentStory.id
  );
  const shuffledStories = shuffleArray(filteredStoriesExcludingCurrent);
  return shuffledStories.slice(0, 10);
}

export function chapterOfCurrentStory(chaptersJson, currentStory) {
  let chapter = chaptersJson.chapters.filter(
    (chapter) => chapter.code === currentStory.chapter
  );
  return chapter;
}

function shuffleArray(array) {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
}

export function findAdjacentStories(stories, currentStory) {
  // Filter stories with the same chapter as the current story
  const filteredStories = stories.filter(
    (story) =>
      story.chapter === currentStory.chapter &&
      story.idDocument !== currentStory.idDocument
  );

  // Find the index of the current story
  const currentIndex = filteredStories.findIndex(
    (story) => story.idDocument === currentStory.id
  );

  // Remove the current story from the filtered list
  if (currentIndex !== -1) {
    filteredStories.splice(currentIndex, 1);
  }

  // Find the previous and next stories
  let prevStoryId = null;
  let prevStoryUrlIt = null;
  let prevStoryUrlEn = null;
  if (currentIndex > 0) {
    const previousStoryObj = filteredStories[currentIndex - 1];
    prevStoryId = previousStoryObj.idDocument;
    prevStoryUrlIt = previousStoryObj.urlIt;
    prevStoryUrlEn = previousStoryObj.urlEn;
  }
  let idNextStory = null;
  let urlItNextStory = null;
  let urlEnNextStory = null;
  if (currentIndex < filteredStories.length) {
    const nextStoryObj = filteredStories[currentIndex];
    idNextStory = nextStoryObj.idDocument;
    urlItNextStory = nextStoryObj.urlIt;
    urlEnNextStory = nextStoryObj.urlEn;
  }

  return [
    {
      previousStoryId: prevStoryId,
      previousStoryUrlIt: prevStoryUrlIt,
      previousStoryUrlEn: prevStoryUrlEn,
    },
    {
      nextStoryId: idNextStory,
      nextStoryUrlIt: urlItNextStory,
      nextStoryUrlEn: urlEnNextStory,
    },
  ];
}

export function findAdjacentChapters(chapters, currentChapter) {
  // Find the index of the current chapter
  const currentIndex = chapters.findIndex(
    (chapter) => chapter.idDocument === currentChapter.id
  );

  // Remove the current chapter from the filtered list
  let prevChapterId = null;
  let prevChapterUrlIt = null;
  let prevChapterUrlEn = null;
  if (currentIndex > 0) {
    const previousChapterObj = chapters[currentIndex - 1];
    prevChapterId = previousChapterObj.idDocument;
    prevChapterUrlIt = previousChapterObj.urlIt;
    prevChapterUrlEn = previousChapterObj.urlEn;
  }
  let idNextChapter = null;
  let urlItNextChapter = null;
  let urlEnNextChapter = null;
  if (currentIndex < chapters.length - 1) {
    const nextChapterObj = chapters[currentIndex + 1];
    idNextChapter = nextChapterObj.idDocument;
    urlItNextChapter = nextChapterObj.urlIt;
    urlEnNextChapter = nextChapterObj.urlEn;
  }

  return [
    {
      previousChapterId: prevChapterId,
      previousChapterUrlIt: prevChapterUrlIt,
      previousChapterUrlEn: prevChapterUrlEn,
    },
    {
      nextChapterId: idNextChapter,
      nextChapterUrlIt: urlItNextChapter,
      nextChapterUrlEn: urlEnNextChapter,
    },
  ];
}

export function checkLanguage(dataLangIt, dataLangEn, language) {
  if (language == "it-IT" || language == "it") {
    return dataLangIt;
  } else {
    return dataLangEn;
  }
}

export function convertFormatTimestamp(timestamp) {
  const date = new Date(timestamp);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // getMonth() is zero-based
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

export function allStoriesChapter() {}
