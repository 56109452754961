import React, { useEffect, useState } from "react";
import {
  Pagination,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import "./custompagination.css";

const theme = createTheme({
  components: {
    MuiPaginationItem: {
      styleOverrides: {
        text: {
          color: "white", // Replace with the color you want
        },
      },
    },
  },
});

export default function CustomPagination(props) {
  const isMobileScreen = useMediaQuery("(max-width:800px)");

  const [state, setState] = useState({
    currentPage: 1,
  });

  function handleChange(event, value) {
    let nextPage = 1;
    console.log("VALOREEEEEEEEHH ", value);
    props.setPagination(value);
    setState({
      ...state,
      currentPage: value,
    });
  }

  function countPages() {
    return Math.ceil(props.storiesLength / 12);
  }

  useEffect(() => {
    console.log("PAGE IMPOSED :", props.pageImposed);
    setState({
      ...state,
      currentPage: props.pageImposed,
    });
  }, [props.pageImposed]);

  return (
    <div className="pagination-container">
      <ThemeProvider theme={theme}>
        <Pagination
          count={countPages()}
          size={isMobileScreen ? "" : "large"}
          color="primary"
          onChange={handleChange}
          page={state.currentPage}
        />
      </ThemeProvider>
    </div>
  );
}
