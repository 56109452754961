import { useRef, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import FacebookIcon from "@mui/icons-material/Facebook";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LanguageManager from "../LanguageManager/LanguageManager";
import AnimatedLogo from "../AnimatedLogo/AnimatedLogo";
import useScreenRotation from "../../utils/uiUtils";
import { useMediaQuery } from "@mui/material";
import "./navbar.css";

function Navbar() {
  const headerRef = useRef();
  const [isResponsiveNav, setResponsiveNav] = useState(false);
  const { t } = useTranslation();
  const isRotated = useScreenRotation();

  const showNavbar = () => {
    setResponsiveNav(!isResponsiveNav);
  };

  const isMobileScreen = useMediaQuery("(max-width:1024px)");
  const isDesktopScreen = useMediaQuery("(min-width:1024px)");

  return (
    <header
      ref={headerRef}
      className={isResponsiveNav ? "responsive_nav" : ""}
      style={{ zIndex: 1000 }}
    >
      <div className="header-mobile">
        {isMobileScreen && (
          <>
            <div className="left-header-mobile">
              {/* <Link to="/">
                <img src={Logo} alt="logo-mobile" className="logo-mobile"></img>
              </Link> */}
              {/* <Link to="/">
                <h1 style={{ marginLeft: "10px" }}>{t("landsOfTales")}</h1>
              </Link> */}
            </div>
            <button
              className="nav-btn"
              onClick={showNavbar}
              style={{ marginRight: "10px", zIndex: 1002 }}
            >
              {isResponsiveNav ? <CloseIcon /> : <MenuIcon />}
            </button>
          </>
        )}
      </div>
      <nav className={isResponsiveNav ? "responsive_nav" : ""}>
        {isDesktopScreen && (
          <div style={{ zIndex: 2 }}>
            <div className="left-part">
              <Link
                to="/about"
                className="addresses-link"
                style={{ minWidth: "90px" }}
              >
                {t("about")}
              </Link>
              <Link to="/chapters" className="addresses-link">
                {t("chapters")}
              </Link>
            </div>
            <div className="central-right-part"></div>
            <Link
              to="/stories"
              className="addresses-link"
              style={{ width: "70px" }}
            >
              {t("stories")}
            </Link>
            <Link to="/">
              <AnimatedLogo customClass={"logo"} />
            </Link>
            <Link
              to="/blog"
              className="addresses-link"
              style={{ width: "90px" }}
            >
              Blog
            </Link>
            <div className="central-right-part"></div>
            <div className="right-part">
              <div
                className="social-icons-container"
                style={{ marginRight: "15px" }}
              >
                <FacebookIcon style={{ fontSize: "30px" }} />
              </div>
              <LanguageManager />
            </div>
          </div>
        )}
        {isMobileScreen && !isRotated && (
          <div className="mobile-menu">
            <Link to="/" onClick={showNavbar}>
              <AnimatedLogo customClass={"logo-mobile-animated-menu"} />
            </Link>
            <Link
              to="/stories"
              className="addresses-link"
              style={{ marginTop: "5vh" }}
              onClick={showNavbar}
            >
              {t("stories")}
            </Link>
            <Link to="/blog" className="addresses-link" onClick={showNavbar}>
              Blog
            </Link>
            <div style={{ height: "3vh" }}></div>
            <Link
              to="/chapters"
              className="addresses-link"
              onClick={showNavbar}
            >
              {t("chapters")}
            </Link>
            <Link to="/about" className="addresses-link" onClick={showNavbar}>
              {t("about")}
            </Link>
            <div style={{ marginTop: "5vh" }}>
              <LanguageManager />
            </div>
          </div>
        )}
        {isMobileScreen && isRotated && (
          <div className="mobile-menu-horizontal">
            <Link to="/" onClick={showNavbar}>
              <AnimatedLogo customClass={"logo-mobile-animated-menu"} />
            </Link>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "20vw",
              }}
            >
              <Link
                to="/stories"
                className="addresses-link-horizontal"
                onClick={showNavbar}
              >
                {t("stories")}
              </Link>
              <Link
                to="/blog"
                className="addresses-link-horizontal"
                onClick={showNavbar}
              >
                Blog
              </Link>
              <div style={{ height: "3vh" }}></div>
              <Link
                to="/chapters"
                className="addresses-link"
                onClick={showNavbar}
              >
                {t("chapters")}
              </Link>
              <Link
                to="/about"
                className="addresses-link-horizontal"
                onClick={showNavbar}
              >
                {t("about")}
              </Link>
            </div>
            <div style={{ width: "20vw" }}>
              <LanguageManager />
            </div>
          </div>
        )}
      </nav>
    </header>
  );
}

export default Navbar;
