import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Chip from "@mui/material/Chip";
import CloseIcon from "@mui/icons-material/Close";
import { Typography, useMediaQuery } from "@mui/material";

export default function FilterSelect({
  onMenuClick,
  resetFunction,
  dataOfMenu,
  filterName,
  colorChosen,
  filterChosen,
  filterFromExternalState,
}) {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const [showSelect, setShowSelect] = useState(true); // State to control whether to show the Select component
  const isDesktopScreen = useMediaQuery("(min-width:1025px)");
  const [state, setState] = useState({
    filterChosen: "",
  });

  function handleChange(event) {
    const selectedValue = event.target.value;
    setValue(selectedValue);
    onMenuClick(selectedValue);
    setShowSelect(false); // Hide the Select component after an option is selected
    setState({
      ...state,
      filterChosen: selectedValue,
    });
  }

  function handleRemoveChip() {
    setValue(""); // Reset the selected value
    resetFunction();
    setShowSelect(true); // Show the Select component again
    setState({
      ...state,
      filterChosen: "",
    });
  }

  function setStateFilter() {
    setState({
      ...state,
      filterChosen: filterChosen,
    });
  }

  useEffect(() => {
    setStateFilter();
  }, []);

  return (
    <div>
      {filterFromExternalState == "" && (
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={filterFromExternalState}
          onChange={handleChange}
          displayEmpty
          endIcon={<KeyboardArrowDownIcon />}
          sx={{
            color: colorChosen,
            minHeight: "55px",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#2196f3",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: colorChosen,
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: colorChosen,
            },
          }}
        >
          <MenuItem value="" disabled>
            {t(filterName)}
          </MenuItem>
          {dataOfMenu.map((item, key) => (
            <MenuItem key={key} value={item}>
              {t(item)}
            </MenuItem>
          ))}
        </Select>
      )}
      {filterFromExternalState != "" && (
        <Chip
          label={
            <Typography
              variant="body1"
              sx={{ fontFamily: "sans-serif", fontSize: "16px" }}
            >
              {t(filterFromExternalState)}
            </Typography>
          }
          onDelete={handleRemoveChip}
          deleteIcon={<CloseIcon />}
          sx={{
            color: "white",
            backgroundColor: "#2196f3",
            height: "55px",
            display: "flex",
            alignItems: "center",
          }}
        />
      )}
    </div>
  );
}
