import { db } from "../firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import {
  getJsonById,
  isTimeToUpdateSessionStorage,
  setJsonPostsInSessionStorage,
} from "./firebaseUtils";
import { urlPostsJson } from "./dataUtils";

const postsCollectionRef = collection(db, "posts");
const postsJsonCollectionRef = collection(db, "posts_json");

//-------------------------------------------------//

const getPostsFromSearch = async (searchBy, wordSearched) => {
  console.log("Parametri cercati: ", searchBy, wordSearched);

  try {
    const querySnapshot = await getDocs(
      query(
        postsCollectionRef,
        where(searchBy, ">=", wordSearched),
        where(searchBy, "<=", wordSearched + "\uf8ff")
      )
    );

    // Debug: Log the results
    console.log("Search results: ", querySnapshot.docs);

    return querySnapshot;
  } catch (error) {
    console.error("Error searching posts:", error);
    throw error; // Rethrow the error to handle it further up the call stack if needed
  }
};

// ------ GET POSTS JSON ------------- //

const getPostsJson = async () => {
  try {
    const jsonDataString = sessionStorage.getItem("postsJson");
    const jsonData = JSON.parse(jsonDataString);
    //console.log("stories JSON data: ", jsonData);

    return jsonData;
  } catch (error) {
    console.error("Error retrieving stories JSON:", error);
    throw error;
  }
};

//--------------------------------------- //

function printTime() {
  const isoTimestamp = new Date().toISOString();
  console.log(isoTimestamp);
  return isoTimestamp;
}

async function checkPostsSessionStorage() {
  let postsData = await getPostsJson();
  if (postsData == null || isTimeToUpdateSessionStorage(postsData.timeStamp)) {
    postsData = await getJsonById(urlPostsJson, "posts");
    postsData.posts.sort((a, b) => {
      const dateA = new Date(a.timestamp);
      const dateB = new Date(b.timestamp);
      return dateB - dateA;
    });
    setJsonPostsInSessionStorage(postsData);
  }
  return postsData;
}

async function getPostById(postId) {
  try {
    const postDocRef = doc(postsCollectionRef, postId);
    const postDocSnapshot = await getDoc(postDocRef);

    if (postDocSnapshot.exists()) {
      const postData = postDocSnapshot.data();
      return { id: postDocSnapshot.id, ...postData };
    } else {
      console.log("No such document exists!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching post by ID:", error);
    throw error;
  }
}

function findSuggestedPosts(posts, postDocumentId) {
  // Sort posts by timestamp
  const sortedPosts = [...posts].sort(
    (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
  );

  // Find index of the target post
  const targetIndex = sortedPosts.findIndex(
    (post) => post.idDocument === postDocumentId
  );
  if (targetIndex === -1) {
    return [];
  }

  // Get up to 5 posts before and after the target, excluding the target itself
  const before = sortedPosts.slice(Math.max(0, targetIndex - 5), targetIndex);
  const after = sortedPosts.slice(targetIndex + 1, targetIndex + 6);

  // Adjust if total collected is less than 10
  let combinedPosts = before.concat(after);
  if (combinedPosts.length < 10) {
    const additionalNeeded = 10 - combinedPosts.length;
    if (before.length < 5) {
      combinedPosts = combinedPosts.concat(
        sortedPosts.slice(targetIndex + 6, targetIndex + 6 + additionalNeeded)
      );
    } else {
      combinedPosts = sortedPosts
        .slice(Math.max(0, targetIndex - 5 - additionalNeeded), targetIndex)
        .concat(combinedPosts);
    }
  }

  console.log("POST SUGGERITI :", combinedPosts.slice(0, 10));

  return combinedPosts.slice(0, 10);
}

function findAdjacentPosts(posts, currentPost) {
  // Filter stories with the same chapter as the current story

  // Find the index of the current story
  const currentIndex = posts.findIndex(
    (post) => post.idDocument === currentPost.id
  );

  // Remove the current story from the filtered list
  if (currentIndex !== -1) {
    posts.splice(currentIndex, 1);
  }

  // Find the previous and next stories
  let prevPostId = null;
  let prevPostUrlIt = null;
  let prevPostUrlEn = null;
  if (currentIndex > 0) {
    const previousPostObj = posts[currentIndex - 1];
    prevPostId = previousPostObj.idDocument;
    prevPostUrlIt = previousPostObj.urlIt;
    prevPostUrlEn = previousPostObj.urlEn;
  }

  let idNextPost = null;
  let urlItNextPost = null;
  let urlEnNextPost = null;
  if (currentIndex < posts.length - 1) {
    const nextPostObj = posts[currentIndex];
    idNextPost = nextPostObj.idDocument;
    urlItNextPost = nextPostObj.urlIt;
    urlEnNextPost = nextPostObj.urlEn;
  }
  return [
    {
      previousPostId: prevPostId,
      previousPostUrlIt: prevPostUrlIt,
      previousPostUrlEn: prevPostUrlEn,
    },
    {
      nextPostId: idNextPost,
      nextPostUrlIt: urlItNextPost,
      nextPostUrlEn: urlEnNextPost,
    },
  ];
}

export {
  printTime,
  getPostsJson,
  checkPostsSessionStorage,
  getPostById,
  findSuggestedPosts,
  findAdjacentPosts,
};
