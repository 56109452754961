import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import About from "../screens/About";
import Audioguide from "../screens/Audioguide";
import Blog from "../screens/Blog";
import BlogPost from "../screens/BlogPost";
import Contacts from "../screens/Contacts";
import CookiesPolicy from "../screens/CookiesPolicy";
import Home from "../screens/Home";
import Press from "../screens/Press";
import Privacy from "../screens/Privacy";
import Stories from "../screens/Stories";
import StoryDetail from "../screens/StoryDetail";
import Chapters from "../screens/Chapters";
import ChapterDetail from "../screens/ChapterDetail";
import PageNotFound from "../screens/PageNotFound";
import { useEffect } from "react";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/HeaderBar/Navbar";

import "../styles/general.css";

function Routing(props) {
  useEffect(() => {}, []);
  const location = useLocation();
  const { hash, pathname, search } = location;
  return (
    <div className="bg">
      {pathname != "/" && pathname != "/home" && <Navbar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/audioguide" element={<Audioguide />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/blogpost/:id/:url" element={<BlogPost />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/cookiespolicy" element={<CookiesPolicy />} />
        <Route path="/press" element={<Press />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/stories" element={<Stories />} />
        <Route path="/stories/story/:id/:url" element={<StoryDetail />} />
        <Route path="/chapters" element={<Chapters />} />
        <Route path="/chapters/chapter/:id/:url" element={<ChapterDetail />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default Routing;
