import * as React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import "./customcard.css";

function Media(props) {
  return (
    <Grid container spacing={2} sx={{ justifyContent: "center" }}>
      {[1, 2, 3].map((item, index) => (
        <Box
          key={index}
          sx={{ width: 400, marginLeft: 3, marginRight: 3, my: 5 }}
        >
          <Skeleton variant="rectangular" width={420} height={160} />
          <Box sx={{ pt: 0.5 }}>
            <Skeleton />
            <Skeleton width="60%" />
          </Box>
        </Box>
      ))}
    </Grid>
  );
}

export default function SkeletonCardsGrid() {
  return (
    <Box
      sx={{
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Media />
    </Box>
  );
}
