import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

import "./longcard.css";
import { Link } from "react-router-dom";

export default function LongCard(props) {
  const { t, i18n } = useTranslation();
  const isDesktopScreen = useMediaQuery("(min-width:1025px)");
  const handleClick = () => {
    if (props.refetchFunction) {
      props.refetchFunction();
    }
  };

  return (
    <div className="custom-longcard-container">
      <Card
        sx={{
          width: isDesktopScreen ? "80%" : "100%",
          position: "relative", // Add relative positioning
        }}
        className="image-container"
      >
        <CardActionArea>
          <Link to={props.url} onClick={handleClick}>
            <CardMedia
              component="div"
              sx={{
                width: "100%",
                height: "200px", // Adjusted height
                backgroundImage: `url(${
                  props.content.urlWebImage
                    ? props.content.urlWebImage
                    : props.content.webImage
                })`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              {/* Title */}
              <Typography
                component="div"
                sx={{
                  position: "absolute",
                  left: "50%",
                  top: "40%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1, // Ensure title appears on top
                  // color: "white", // Title color
                  // backgroundColor: "rgba(0, 0, 0, 0.5)", // Shaded dark background
                  // padding: "8px", // Padding around the title
                  fontSize: isDesktopScreen ? "30px" : "20px",
                  width: "50%",
                }}
                className="shade-title"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "70vw",
                }}
              >
                {i18n.language === "it"
                  ? props.content.titleIt
                  : props.content.titleEn}
              </Typography>
            </CardMedia>

            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: `${props.minWidth}`,
                maxWidth: `${props.maxWidth}`,
              }}
            >
              {/* Description */}
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  marginTop: "16px",
                  fontSize: "16px", // Adjust spacing from title
                }}
              >
                {i18n.language === "it"
                  ? props.content.descriptionIt
                  : props.content.descriptionEn}
              </Typography>
            </CardContent>
          </Link>
        </CardActionArea>
      </Card>
    </div>
  );
}
