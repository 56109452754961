import { createSlice } from "@reduxjs/toolkit";

// Action Creator
//export const setLanguage = (obj) => (dispatch) => {
export const setLanguage = (str) => (dispatch) => {
  try {
    //return dispatch(setLanguageAction(obj));
    return dispatch(setLanguageAction(str));
  } catch (e) {
    return dispatch(errorLanguageAction(e.message));
  }
};

// Action Creator
export const initLanguage = () => async (dispatch) => {
  try {
    return dispatch(initLanguageAction());
  } catch (e) {
    return dispatch(errorLanguageAction(e.message));
  }
};

export const setRole = (str) => (dispatch) => {
  try {
    //return dispatch(setLanguageAction(obj));
    return dispatch(setRoleAction(str));
  } catch (e) {
    return dispatch(errorRoleAction(e.message));
  }
};

// Slice
const roleDuck = createSlice({
  name: "roleDuck",
  initialState: {
    language: "en",
    error: null,
    role: null,
  },
  reducers: {
    setLanguageAction: (state, action) => {
      state.language = action.payload;
    },
    initLanguageAction: (state) => {
      state.language = null;
      state.error = null;
    },
    errorLanguageAction: (state, action) => {
      state.error = action.payload.error;
    },
    setRoleAction: (state, action) => {
      console.log("dentro setRoleAction :", state, action);
      state.role = action.payload;
      console.log("state.role :", state.role);
    },
    errorRoleAction: (state, action) => {
      state.error = action.payload.error;
    },
  },
});

export default roleDuck.reducer;

// Actions
const {
  setLanguageAction,
  initLanguageAction,
  errorLanguageAction,
  setRoleAction,
  errorRoleAction,
} = roleDuck.actions;
