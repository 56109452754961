import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MenuItem, FormControl, Select } from "@mui/material";
import { setLanguage } from "../../redux/duck/language/languageDuck";
import { useDispatch } from "react-redux";
import "./languagemanager.css";
import enFlag from "../../assets/images/languageIcons/gb.svg";
import itFlag from "../../assets/images/languageIcons/it.svg";
import otherEnFlag from "../../assets/images/languageIcons/gb.svg"; // Change this to the path of your other English flag image
import otherItFlag from "../../assets/images/languageIcons/it.svg"; // Change this to the path of your other Italian flag image

const LANGS = [
  {
    value: "it",
    label: "italian",
    image: itFlag,
  },
  {
    value: "en",
    label: "english",
    image: enFlag,
  },
];

export default function LanguageManager() {
  const { i18n } = useTranslation();
  const [state, setState] = useState({
    lang: i18n.language || "it", // Set the initial language based on i18n
  });

  const dispatch = useDispatch();

  const chooseLanguage = (event) => {
    const selectedLanguage = event.target.value;
    dispatch(setLanguage(selectedLanguage));

    if (i18n.language !== selectedLanguage && i18n.changeLanguage) {
      i18n.changeLanguage(selectedLanguage);
      // window.dispatchEvent(new Event("setUrlByLang"));
    }

    setState({
      ...state,
      lang: selectedLanguage,
    });
  };

  useEffect(() => {
    // Set the initial language based on i18n
    setState({
      ...state,
      lang: i18n.language || "it",
    });

    dispatch(setLanguage(i18n.language || "it"));
  }, []);

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }}>
      <Select
        value={state.lang}
        onChange={chooseLanguage}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        className="language-select"
        sx={{ color: "white" }}
      >
        {LANGS.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <img
              src={option.value === "en" ? otherEnFlag : otherItFlag}
              alt={option.label}
              className="flag-logo"
            ></img>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
