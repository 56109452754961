import React, { useEffect, useState } from "react";
import "../styles/general.css";
import "../styles/blog.css";
import { useTranslation } from "react-i18next";
import CustomPagination from "../components/Pagination/CustomPagination";
import {
  categoriesPostsUtils,
  google_analytics_measurement_id,
} from "../utils/dataUtils";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import FilterListIcon from "@mui/icons-material/FilterList";

//Components
import CustomCard from "../components/Card/CustomCard";
import SkeletonCardsGrid from "../components/Card/SkeletonCardsGrid";
import FilterButton from "../components/FilterButton/FilterButton";
import {
  Box,
  IconButton,
  Modal,
  TextField,
  useMediaQuery,
} from "@mui/material";
import useScreenRotation, { scrollToStartSection } from "../utils/uiUtils";
import { checkPostsSessionStorage } from "../utils/blogFirebaseUtils";
import ReactGA from "react-ga4";

export default function Blog(props) {
  const { t, i18n } = useTranslation();
  const isDesktopScreen = useMediaQuery("(min-width:1025px)");
  const isReallyMobileScreen = useMediaQuery("(max-width:400px)");
  const isRotated = useScreenRotation();
  const [state, setState] = useState({
    ordersList: null,
    posts: null,
    page: 0,
    rowsPerPage: 10,
    responsePosts: null,
    jsonPosts: [],
    jsonPostsFixed: [],
    filterCategory: "",
    filterTitle: "",
    isFilterTitleOn: false,
    startPagination: 0,
    endPagination: 12,
    currentPage: "",
    imposedPage: 0,
  });
  ReactGA.initialize(google_analytics_measurement_id);
  ReactGA.send({ hitType: "pageview", page: "/blog", title: "Blog view" });

  async function fetchJsonPosts() {
    scrollToStartSection();
    const dataFromJson = await checkPostsSessionStorage();
    console.log("DATA FROM JSON :", dataFromJson);
    setState({
      ...state,
      jsonPosts: dataFromJson,
      jsonPostsFixed: dataFromJson,
    });
  }

  useEffect(() => {
    fetchJsonPosts();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [state.filterCategory]); // Reapply filters whenever category or title filters change

  function setPage(page) {
    console.log("Da positzione: ", page * 12 - 12, " a positione: ", page * 12);
    setState({
      ...state,
      startPagination: page * 12 - 12,
      endPagination: page * 12,
      currentPage: page,
    });
  }

  function resetFilterCategory() {
    setState({
      ...state,
      filterCategory: "",
      startPagination: state.currentPage * 12 - 12,
      endPagination: state.currentPage * 12,
      currentPage: 1,
    });
  }

  function resetFilterTitle() {
    let filteredPosts = state.jsonPostsFixed.posts;
    if (state.filterCategory !== "") {
      filteredPosts = filteredPosts.filter((post) =>
        post.categories?.includes(state.filterCategory)
      );
    }

    setState({
      ...state,
      jsonPosts: { ...state.jsonPosts, posts: filteredPosts },
      filterTitle: "",
      startPagination: state.currentPage * 12 - 12,
      endPagination: state.currentPage * 12,
      isFilterTitleOn: false,
      currentPage: 1,
    });
  }

  function filterByCategory(option) {
    setState({
      ...state,
      filterCategory: option,
      startPagination: 0,
      endPagination: 12,
      currentPage: 1,
    });
  }

  function handleSearch() {
    applyFilters();
  }

  function handleTitleChange(event) {
    setState({
      ...state,
      filterTitle: event.target.value,
    });
  }

  function applyFilters() {
    let filteredPosts = state.jsonPostsFixed.posts;
    let filterTitleOn = false;

    if (state.filterCategory !== "") {
      filteredPosts = filteredPosts.filter((post) =>
        post.categories?.includes(state.filterCategory)
      );
    }

    if (state.filterTitle !== "") {
      const normalizedTitle = state.filterTitle.toLowerCase(); // Normalize the title
      console.log(
        "state.filterTitle.toLowerCase()",
        state.filterTitle.toLowerCase()
      );

      filteredPosts = filteredPosts.filter((item) => {
        return i18n.language == "it"
          ? item.titleIt.toLowerCase().includes(normalizedTitle)
          : item.titleEn.toLowerCase().includes(normalizedTitle);
      });

      filterTitleOn = true;
    }

    setState({
      ...state,
      jsonPosts: {
        ...state.jsonPosts,
        posts: filteredPosts,
      },
      startPagination: 0,
      endPagination: 12,
      isFilterTitleOn: filterTitleOn,
      currentPage: 1,
    });
  }

  function renderFiltersBar() {
    return (
      <div className="filters-bar">
        <div className="text-stories">{t("filterBy")}</div>
        <div>
          <FilterButton
            onMenuClick={filterByCategory}
            resetFunction={resetFilterCategory}
            dataOfMenu={categoriesPostsUtils}
            filterName={"category"}
            colorChosen={"white"}
            filterChosen={state.filterCategory}
            filterFromExternalState={state.filterCategory}
          />
        </div>
        <div className="text-stories" style={{ marginLeft: "40px" }}>
          {t("searchBy")}
        </div>
        <div
          style={{
            color: "white",
            display: "flex",
            alignItems: "center",
          }}
        >
          <TextField
            d="outlined-basic"
            label={t("titlePost")}
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#2196f3",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
              },
              input: { color: "white" },
              borderColor: "white",
            }}
            InputLabelProps={{ style: { color: "white" } }}
            onChange={handleTitleChange}
            value={state.filterTitle}
            // Add endAdornment for the cancel icon
            InputProps={{
              endAdornment: state.filterTitle && (
                <IconButton
                  aria-label="clear search"
                  onClick={resetFilterTitle}
                  edge="end"
                  style={{ color: "white" }} // Adjust color
                >
                  <CancelIcon />
                </IconButton>
              ),
            }}
          />
          <div style={{ marginLeft: "10px" }}>
            <IconButton
              aria-label="fingerprint"
              sx={{
                backgroundColor: "#2196f3",
                color: "white",
                "&:hover": {
                  backgroundColor: "white",
                  color: "black",
                },
              }}
              onClick={handleSearch}
            >
              <SearchIcon />
            </IconButton>
          </div>
        </div>
      </div>
    );
  }

  function handleFilterModalOpen() {
    setState({
      ...state,
      isFilterModalOpen: true,
    });
  }

  function handleFilterModalClose() {
    setState({
      ...state,
      isFilterModalOpen: false,
    });
  }

  function renderFiltersModal() {
    return (
      <Modal
        open={state.isFilterModalOpen}
        onClose={handleFilterModalClose}
        aria-labelledby="filter-modal-title"
        aria-describedby="filter-modal-description"
      >
        <Box
          sx={{
            justifyContent: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleFilterModalClose}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              color: "action",
            }}
          >
            <CancelIcon />
          </IconButton>
          <div className="filters-modal">
            <div
              style={{
                fontSize: 16,
                color: "black",
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              {t("filterBy")}
            </div>
            <div style={{ marginBottom: "10px" }}>
              <FilterButton
                onMenuClick={filterByCategory}
                resetFunction={resetFilterCategory}
                dataOfMenu={categoriesPostsUtils}
                filterName={"category"}
                colorChosen={"black"}
                filterChosen={state.filterCategory}
                filterFromExternalState={state.filterCategory}
              />
            </div>
            <div
              style={{
                fontSize: 16,
                color: "black",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              {t("searchBy")}
            </div>
            <div
              style={{
                color: "black",
                display: "flex",
                alignItems: "center",
              }}
            >
              <TextField
                d="outlined-basic"
                label={t("titlePost")}
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#2196f3",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "black",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "black",
                    },
                  },
                  input: { color: "black" },
                  borderColor: "white",
                }}
                InputLabelProps={{ style: { color: "black" } }}
                onChange={handleTitleChange}
                value={state.filterTitle}
                // Add endAdornment for the cancel icon
                InputProps={{
                  endAdornment: state.filterTitle && (
                    <IconButton
                      aria-label="clear search"
                      onClick={resetFilterTitle}
                      edge="end"
                      style={{ color: "black" }} // Adjust color
                    >
                      <CancelIcon />
                    </IconButton>
                  ),
                }}
              />
              <div style={{ marginLeft: "10px" }}>
                <IconButton
                  aria-label="fingerprint"
                  sx={{
                    backgroundColor: "#2196f3",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "black",
                    },
                  }}
                  onClick={handleSearch}
                >
                  <SearchIcon />
                </IconButton>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    );
  }

  function renderMobileFilterButton() {
    return (
      <div
        style={{
          display: "flex",
          width: "85%",
          justifyContent: "start",
        }}
      >
        <IconButton
          aria-label="fingerprint"
          sx={{
            backgroundColor: "#2196f3",
            color: "white",
            "&:hover": {
              backgroundColor: "white",
              color: "black",
            },
          }}
          onClick={handleFilterModalOpen}
        >
          <FilterListIcon />
        </IconButton>
      </div>
    );
  }

  function mapPosts() {
    return (
      <div className="card-container">
        {state.jsonPosts?.posts
          ?.slice(state.startPagination, state.endPagination)
          .map((post, key) => (
            <CustomCard
              content={post}
              withText={true}
              maxWidth={isReallyMobileScreen ? "100%" : "400px"}
              url={`/blog/blogpost/${post.idDocument}/${
                i18n.language == "it" ? post.urlIt : post.urlEn
              }`}
            />
          ))}
        <div style={{ width: "100%", justifyContent: "center" }}>
          {!state.jsonPosts?.posts && (
            <>
              <SkeletonCardsGrid direction={"row"} />{" "}
              <SkeletonCardsGrid direction={"row"} />
            </>
          )}
          {state.jsonPosts?.posts?.slice(
            state.startPagination,
            state.endPagination
          ).length <= 0 && <p className="no-story-found">{t("noPostFound")}</p>}
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="main-container-component">
        <div className="title">Blog</div>
        {isDesktopScreen && renderFiltersBar()}
        {!isDesktopScreen && renderFiltersModal()}
        {!isDesktopScreen && renderMobileFilterButton()}
        <div className="stories-grid">{mapPosts()}</div>
        <CustomPagination
          setPagination={setPage}
          storiesLength={state.jsonPosts?.posts?.length}
          pageImposed={state.currentPage}
        />
      </div>
    </div>
  );
}
