import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import PinterestIcon from "@mui/icons-material/Pinterest";
import InstagramIcon from "@mui/icons-material/Instagram";
import "../../styles/general.css"; // Import your generic CSS file

function SocialIcons() {
  return (
    <div className="text-teal-500">
      <span
        className="p-2 cursor-pointer inline-flex items-center
        rounded-full bg-gray-700 mx-1.5 text-xl hover:text-gray-100 hover:bg-teal-500
        duration-300 "
      >
        <FacebookIcon />
      </span>
      <span
        className="p-2 cursor-pointer inline-flex items-center
        rounded-full bg-gray-700 mx-1.5 text-xl hover:text-gray-100 hover:bg-teal-500
        duration-300 "
      >
        <PinterestIcon />
      </span>
      <span
        className="p-2 cursor-pointer inline-flex items-center
        rounded-full bg-gray-700 mx-1.5 text-xl hover:text-gray-100 hover:bg-teal-500
        duration-300 "
      >
        <InstagramIcon />
      </span>
    </div>
  );
}

export default SocialIcons;
