import React, { useEffect, useState } from "react";
import "../styles/general.css";
import "../styles/chapterdetail.css";
import "../styles/about.css";
import { useTranslation } from "react-i18next";
import { List, ListItem, Paper, styled, useMediaQuery } from "@mui/material";
import aboutMeBg from "../assets/images/aboutMeBg.jpg";
import profilePicture from "../assets/images/profilePicture.jpg";

import useScreenRotation, { scrollToStartSection } from "../utils/uiUtils";
import { useParams } from "react-router-dom";
import AboutSocials from "../components/AboutSocials/AboutSocials";

import ReactGA from "react-ga4";
import { google_analytics_measurement_id } from "../utils/dataUtils";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: 60,
  lineHeight: "60px",
}));

export default function About(props) {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const isDesktopScreen = useMediaQuery("(min-width:1025px)");
  const isSmallScreen = useMediaQuery("(max-width:900px)");
  const isMobileScreen = useMediaQuery("(min-width:600px)");
  const isLittleMobileScreen = useMediaQuery("(max-width:500px)");
  const isReallyMobileScreen = useMediaQuery("(max-width:400px)");
  const isRotated = useScreenRotation();
  ReactGA.initialize(google_analytics_measurement_id);
  ReactGA.send({ hitType: "pageview", page: "/about", title: "About view" });

  const [state, setState] = useState({
    storyObj: {},
    storyLocation: [],
    idStoryFromUrl: "",
    startPagination: 0,
    endPagination: 12,
    currentPage: "",
    imposedPage: 0,
  });

  useEffect(() => {
    scrollToStartSection();
    console.log("18n.language", i18n.language);
  }, []);

  return (
    <div id="start">
      <div>
        <img src={aboutMeBg} className="background-picture"></img>
        <div className="dark-shade"></div>
        <div className="dark-shade-bottom-up"></div>
        <div className="dark-shade-bottom-down"></div>
        <div className="main-container-component">
          {isDesktopScreen && (
            <>
              <div className="title shade-title" style={{ zIndex: 1 }}>
                {t("about")}
              </div>
            </>
          )}
          {!isDesktopScreen && (
            <div
              style={{
                zIndex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <div
                className="title shade-title"
                style={{
                  zIndex: 1,
                  marginTop: "-30vh",
                  textAlign: "center",
                  marginTop: {
                    if(isSmallScreen) {
                      "15px";
                    },
                    if(isRotated) {
                      "25px";
                    },
                  },
                }}
              >
                {t("about")}
              </div>
            </div>
          )}
        </div>
        <div className="pic-and-overview-super-container">
          <div
            className={
              isDesktopScreen
                ? "pic-and-overview-container"
                : "pic-and-overview-container-mobile"
            }
          >
            <img
              src={profilePicture}
              className={isDesktopScreen ? "profile-pic" : "profile-pic-mobile"}
            />
            {i18n.language == "en" && (
              <List
                style={{
                  color: "white",
                  marginBottom: "20px",
                  fontFamily: "Roboto",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  paddingTop: "20px",
                  fontSize: "17px",
                }}
                className={
                  isDesktopScreen ? "left-menu-about" : "left-menu-about-mobile"
                }
              >
                <p
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "20px",
                    fontWeight: "bold",
                    marginBottom: "30px",
                  }}
                >
                  Hello there!
                </p>
                My name's{" "}
                <b style={{ fontFamily: "Roboto" }}>Martino Manzolini</b> and
                I'm 26 years old
                <b style={{ fontFamily: "Roboto" }}> software engineer</b>{" "}
                currently working as consultant{" "}
                <b style={{ fontFamily: "Roboto" }}>frontend developer</b>.
                <p
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "17px",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Even though I'm using Typescript and React, I have also worked
                  as backend developer using Java and Spring Boot. I developed
                  projects with React Native, Angular and different Augmented
                  Reality applications for Android using C#.
                </p>
                <p
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "17px",
                    marginTop: "30px",
                    marginBottom: "10px",
                  }}
                >
                  If you want to keep in touch, feel free to reach me out at:
                  {"     "}
                </p>
                <p
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "17px",
                    marginBottom: "10px",
                    fontWeight: "bold",
                  }}
                >
                  martino.manzolini@gmail.com
                </p>
                <div className="social-icons">
                  <AboutSocials />
                </div>
              </List>
            )}
            {i18n.language == "it" && (
              <List
                style={{
                  color: "white",
                  marginBottom: "20px",
                  fontFamily: "Roboto",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  paddingTop: "20px",
                  fontSize: "17px",
                }}
                className="left-menu"
              >
                <p
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "20px",
                    fontWeight: "bold",
                    marginBottom: "30px",
                  }}
                >
                  Ehilà!
                </p>
                Mi chiamo{" "}
                <b style={{ fontFamily: "Roboto" }}>Martino Manzolini</b>, ho 26
                anni e sono un
                <b style={{ fontFamily: "Roboto" }}>
                  {" "}
                  ingegnere informatico.
                </b>{" "}
                Lavoro come
                <b style={{ fontFamily: "Roboto" }}> frontend developer </b>
                nell'ambito della consulenza.
                <p
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "17px",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Anche se le tecnologie che uso al momento sono Typescript e
                  React, ho lavorato anche come{" "}
                  <b style={{ fontFamily: "Roboto" }}>backend developer</b> con
                  Java e Spring Boot. Ho sviluppato progetti con React Native,
                  Angular e diverse applicazioni di Realtà Aumentata per Android
                  in C#.
                </p>
                <p
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "17px",
                    marginTop: "30px",
                    marginBottom: "10px",
                  }}
                >
                  Se vuoi contattarmi, non esitare a scrivermi al seguente
                  recapito:
                  {"     "}
                </p>
                <p
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "17px",
                    marginBottom: "10px",
                    fontWeight: "bold",
                  }}
                >
                  martino.manzolini@gmail.com
                </p>
                <div className="social-icons">
                  <AboutSocials />
                </div>
              </List>
            )}
          </div>
        </div>
        <div
          className={
            isLittleMobileScreen ? "about-container-mobile" : "about-container"
          }
          style={{
            marginTop: {
              if(isRotated) {
                "-50px";
              },
            },
          }}
        >
          <div
            className={
              isLittleMobileScreen
                ? "about-description-mobile"
                : "about-description"
            }
            id="body-story"
          >
            {i18n.language == "en" && (
              <>
                {" "}
                <div
                  style={{
                    fontFamily: "Roboto, sans-serif",
                    fontSize: "25px",
                    fontWeight: "bold",
                    color: "white",
                    marginTop: "20px",
                  }}
                >
                  Coding legends
                </div>
                <div className="content-about-extended">
                  One of the responsibilities of a{" "}
                  <b className="bold">software engineer </b>
                  is to provide people with simple and efficient access to
                  functionalities and data that previously might have been
                  inaccessible. That is my job, to simplify, but I aim to extend
                  this role beyond my professional life as well.
                </div>
                <div className="content-about-extended">
                  Traveling around Europe, I’ve noticed how every culture is
                  deeply rooted in the land from which it originates. This
                  connection is evident not only in the language, food, or music
                  but also in the stories. I’ve been amazed to find that in some
                  regions, every place conceals tales, some factual, some
                  realistic, others legendary. In certain cases, these{" "}
                  <b className="bold">
                    {" "}
                    stories are so ingrained in the culture that they seem to
                    weave the very fabric of the people’s cultural identity
                  </b>
                  . In my native alpine region of Italy, local culture is
                  preserved through gastronomy, dances, and music, but the tales
                  are often unknown.
                </div>
                <div className="content-about-extended">
                  For these reasons, I have decided to create a website using{" "}
                  <b className="bold">React and Firebase</b>. Here people can
                  explore <b className="bold">the tales of these lands</b> and
                  perceive these places through new lens.
                </div>
                <div className="content-about-extended"></div>
              </>
            )}
            {i18n.language == "it" && (
              <>
                {" "}
                <div
                  style={{
                    fontFamily: "Roboto, sans-serif",
                    fontSize: "25px",
                    fontWeight: "bold",
                    color: "white",
                    marginTop: "20px",
                  }}
                >
                  Programmando leggende
                </div>
                <div className="content-about-extended">
                  Una delle responsabilità di un{" "}
                  <b className="bold">ingegnere informatico</b> è quella di
                  fornire alle persone un accesso semplice ed efficiente a nuove
                  funzionalità software o a informazioni in precedenza
                  difficilmente consultabili. Questo è il mio lavoro,
                  semplificandone il concetto, e ho sempre provato ad applicarne
                  i principi anche al di fuori della mia vita professionale.
                </div>
                <div className="content-about-extended">
                  Viaggiando per l’Europa mi sono reso conto come ogni cultura
                  sia profondamente radicata nella propria terra d'origine. Tale
                  connessione è evidente non solo nella lingua, nel cibo o nella
                  musica, ma soprattutto nei racconti popolari. Sono rimasto
                  colpito nello scoprire come ogni luogo celi innumerevoli
                  storie: alcune reali, altre realistiche e altre ancora dai
                  toni leggendari. In alcuni casi, questi{" "}
                  <b className="bold">
                    racconti sono così fusi con la cultura locale che sembrano
                    intrecciarsi con il tessuto stesso dell’identità culturale
                  </b>{" "}
                  . Nella regione alpina da cui provengo le tradizioni sono
                  preservate attraverso la gastronomia, le danze e la musica,
                  mentre invece i racconti popolari sono spesso sconosciuti.
                </div>
                <div className="content-about-extended">
                  Per questi motivi ho deciso di creare questo sito, utilizzando
                  <b className="bold"> React e Firebase</b>, per permettere alle
                  persone di esplorare le{" "}
                  <b className="bold">storie di queste terre</b> ed ammirare
                  questi luoghi con una percezione differente.
                </div>
                <div className="content-about-extended"></div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
