import React, { useEffect, useState } from "react";
import "../styles/general.css";
import "../styles/chapters.css";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";

//Components
import { checkChaptersSessionStorage } from "../utils/firebaseUtils";
import { useMediaQuery } from "@mui/material";
import useScreenRotation, { scrollToStartSection } from "../utils/uiUtils";
import LongCard from "../components/LongCard/LongCard";
import ReactGA from "react-ga4";
import { google_analytics_measurement_id } from "../utils/dataUtils";

export default function Chapters(props) {
  const { t, i18n } = useTranslation();
  const isDesktopScreen = useMediaQuery("(min-width:1025px)");
  const isRotated = useScreenRotation();
  const [state, setState] = useState({
    ordersList: null,
    stories: null,
    page: 0,
    rowsPerPage: 10,
    responseStories: null,
    jsonChapters: [],
    filterCategory: "",
    filterChapter: "",
    filterTitle: "",
    isFilterTitleOn: false,
    startPagination: 0,
    endPagination: 12,
    currentPage: "",
    imposedPage: 0,
  });
  ReactGA.initialize(google_analytics_measurement_id);
  ReactGA.send({
    hitType: "pageview",
    page: "/chapters",
    title: "Chapters view",
  });

  async function fetchJsonChapters() {
    scrollToStartSection();
    const dataFromJson = await checkChaptersSessionStorage();
    console.log("DATA FROM JSON :", dataFromJson);
    setState({
      ...state,
      jsonChapters: dataFromJson,
    });
  }

  useEffect(() => {
    fetchJsonChapters();
  }, []);

  function mapChapters() {
    return (
      <Grid container style={{ flexDirection: "column" }}>
        {state.jsonChapters?.chapters?.map((chapter, key) => (
          <LongCard
            content={chapter}
            withText={true}
            minWidth="100%"
            url={`/chapters/chapter/${chapter.idDocument}/${
              i18n.language == "it" ? chapter.urlIt : chapter.urlEn
            }`}
          />
        ))}
      </Grid>
    );
  }

  return (
    <div>
      <div className="main-container-component">
        <div className="title">{t("chapters")}</div>
        <div className="stories-grid">{mapChapters()}</div>
      </div>
    </div>
  );
}
